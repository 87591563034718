import React from "react";

import styles from "./TagCloud.module.css";

/**
 * TagCloud
 */
type TagCloudProps = React.PropsWithChildren;

const TagCloud: React.FunctionComponent<TagCloudProps> = (props) => {
  return <div className={styles.TagCloud}>{props.children}</div>;
};

export default TagCloud;
