import classnames from "classnames/bind";
import React from "react";
import { toDecimal } from "../helpers/number";

import Text from "../Text";

import styles from "./Meter.module.css";

const cx = classnames.bind(styles);

/**
 * Meter
 */
type MeterProps = {
  label: string;
  max: number;
  value: number;
  size?: "sm" | "md" | "lg";
  low?: number;
  high?: number;
  optimum?: "min" | "max" | number;
  min?: number;
  precision?: number;
};

const Meter: React.FC<MeterProps> = (props) => {
  const { max, size } = props;

  // The value should not fall outside the min and max values. Rather than throw
  // an error, we'll just clamp the value to the min and max values.
  const min = props.min ?? 0;
  const value = Math.min(Math.max(props.value, min), max);
  const percentage = ((value - min) / (max - min)) * 100;
  const precisePercentage = toDecimal(percentage, props.precision);

  // The `optimum` determines the directionality of low to high values, used
  // when rendering colors. It should be specified as the upper or lower bound,
  // or as a number in the range of the meter.
  const optimum =
    props.optimum === "min"
      ? min
      : props.optimum === "max"
      ? max
      : props.optimum;

  return (
    <div className={cx(styles.Meter, `Meter--size-${size}`)}>
      <dl>
        <dt>
          <Text
            element="div"
            size={size}
            weight="300"
            className={styles.Meter__label}
          >
            {props.label}
          </Text>
        </dt>
        <dd>
          <Text element="div" size={size} weight="500">
            {value.toLocaleString()} / {max.toLocaleString()}
          </Text>
          <Text element="div" size={size} weight="500">
            {precisePercentage.toLocaleString()}%
          </Text>
        </dd>
      </dl>
      <meter
        className={styles.Meter__meter}
        min={min}
        low={props.low}
        high={props.high}
        optimum={optimum}
        max={max}
        value={value}
      />
    </div>
  );
};

Meter.defaultProps = {
  size: "md",
  low: undefined,
  high: undefined,
  optimum: "max",
  precision: 2,
};

export default Meter;
