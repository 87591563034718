import {
  Button,
  Color,
  DisplayText,
  Text,
  Anchor,
  ButtonStyle,
} from "@proofxyz/moonlight";
import Link from "next/link";

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

function ErrorFallback({ error, resetErrorBoundary }: Props) {
  return (
    <div
      className="flex flex-col sm:flex-row gap-8 items-center justify-center sm:justify-center h-screen  max-w-screen-sm mx-auto"
      role="alert"
    >
      {/* <Image
        src="/images/illustrations/fail/fail-manta-720.png"
        alt=""
        width={360}
        height={360}
        priority={true}
      /> */}

      <div className="text-center sm:text-start">
        <h1 className={DisplayText.md}>Something went wrong!</h1>

        <Text size="xl" color="secondary" className="mt-4">
          Sorry about that, we&rsquo;ve let our developers know.
        </Text>
        <pre className="text-content-secondary text-xs">{error.message}</pre>

        {resetErrorBoundary ? (
          <Button
            color={Color.purple}
            onClick={resetErrorBoundary}
            className="mt-4"
          >
            Try again
          </Button>
        ) : (
          <Link href="/" passHref>
            <Anchor
              style={ButtonStyle.primary}
              className="flex-1 sm:flex-initial w-[240px]"
            >
              Go to homepage
            </Anchor>
          </Link>
        )}
      </div>
    </div>
  );
}

export default ErrorFallback;
