import Text from "../Text";
import React from "react";

/**
 * TwoLiner
 */
export type TwoLinerProps = {
  primary: React.ReactNode;
  secondary: React.ReactNode;
};

export const TwoLiner: React.FC<TwoLinerProps> = (props) => (
  <div>
    <Text element="div" weight="500">
      {props.primary}
    </Text>
    <Text element="div" size="sm" color="secondary">
      {props.secondary}
    </Text>
  </div>
);

export default TwoLiner;
