import { Color, type PolymorphicRef } from "../../types";
import styles from "./TabItem.module.css";
import type { TabItemProps } from "./TabItem.types";
import classnames from "classnames/bind";
import React, { forwardRef } from "react";

const cx = classnames.bind(styles);

const TabItem = forwardRef(
  <C extends React.ElementType = "button">(
    props: TabItemProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const {
      active,
      disabled,
      size,
      color = Color.neutral,
      element = "button",
      children,
      className,
      ...rest
    } = props;
    const Element = element;

    const cn = cx(
      cx(
        "TabItem",
        `TabItem--size-${size}`,
        `TabItem--color-${color}`,
        {
          "TabItem--state-active": active,
          "TabItem--state-inactive": disabled,
        },
        className
      )
    );

    return (
      <Element className={cn} ref={ref} {...rest}>
        {children}
      </Element>
    );
  }
);

TabItem.displayName = "TabItem";

export default TabItem;
