import styles from "./Tag.module.css";
import type { TagProps } from "./Tag.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export default function Tag({
  size,
  variant = "filled",
  className,
  children,
}: TagProps) {
  const cn = cx(
    "Tag",
    `Tag--size-${size}`,
    {
      "Tag--style-filled": variant === "filled",
      "Tag--style-outlined": variant === "outlined",
      "Tag--style-translucent": variant === "translucent",
    },
    className
  );

  return <div className={cn}>{children}</div>;
}
