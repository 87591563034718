import useControlledComponent from "../hooks/useControlledComponent";
import styles from "./Toggle.module.css";
import type { ToggleProps } from "./Toggle.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

/*
 * The toggle can work in either controlled or uncontrolled mode. The mode is automatically
 * picked based on whether selected or defaultSelected is used. This mimics how normal form input
 * elements work.
 */
function Toggle({
  className,
  selected,
  defaultSelected,
  onChange,
  disabled,
  ...props
}: ToggleProps) {
  const { displayValue, onValueChange } = useControlledComponent({
    value: selected,
    defaultValue: defaultSelected,
    onChange,
  });
  const isSelected = !!displayValue;

  const cn = cx(
    "Toggle",
    {
      "Toggle--selected": isSelected,
      "Toggle--disabled": disabled,
    },
    className
  );

  const switchCn = cx("Toggle__switch", {
    "Toggle__switch--selected": isSelected,
  });

  function handleClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    onValueChange(!displayValue);
  }

  return (
    <button className={cn} onClick={handleClick} disabled={disabled} {...props}>
      <div className={switchCn}></div>
    </button>
  );
}

export default Toggle;
