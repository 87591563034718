import Text from "../Text";
import styles from "./Notification.module.css";
import type { NotificationProps } from "./Notification.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export default function Notification({
  count,
  size = "md",
  sizePx = 20,
  color = "primary",
  className,
  textClass,
}: NotificationProps) {
  // Support legacy sizing
  if (sizePx === 24) {
    size = "lg";
  }

  const cn = cx(
    "Notification",
    `Notification--size-${size}`,
    `Notification--color-${color}`,
    className
  );

  const textCn = cx(`Notification--color-${color}__text`, textClass);

  return (
    <div className={cn}>
      <Text size="xs" weight="semibold" className={textCn}>
        {count}
      </Text>
    </div>
  );
}
