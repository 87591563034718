import Head from "next/head";
import { useState, useEffect, createContext, useContext, useRef } from "react";

export enum Modes {
  LIGHT = "light",
  DARK = "dark",
}

export const DEFAULT_MODE = Modes.DARK;
export const ALTERNATE_MODE = Modes.LIGHT;

interface ThemeProviderInterface {
  mode: Modes | null;
  setMode: (mode: Modes) => void;
  isForced?: boolean;
}

const ThemeContext = createContext<ThemeProviderInterface>({
  mode: DEFAULT_MODE,
  setMode: () => {
    // not initialized
  },
  isForced: false,
});

const defaultMode = () => localStorage.theme || DEFAULT_MODE;

type ThemeProviderProps = {
  forceMode?: Modes;
};

const toggleClassList = (mode: Modes) => {
  document.body.classList.toggle(DEFAULT_MODE, mode === DEFAULT_MODE);
  document.body.classList.toggle(ALTERNATE_MODE, mode === ALTERNATE_MODE);
};

export const ThemeProvider: React.FunctionComponent<
  React.PropsWithChildren<ThemeProviderProps>
> = ({ forceMode, children }) => {
  const [mode, setMode] = useState<Modes | null>(forceMode ?? null);

  // We use this to track what the last value of forceMode was. By doing so,
  // we can correctly revert to the users color mode of choice when leaving
  // a page that forced a mode.
  const forceModePrev = useRef(forceMode);
  useEffect(() => {
    if (forceMode) {
      if (mode !== null) {
        setMode(forceMode);
        toggleClassList(mode);
        forceModePrev.current = forceMode;
      }
    } else if (forceModePrev.current !== forceMode) {
      setMode(defaultMode());
      forceModePrev.current = forceMode;
    } else if (mode) {
      toggleClassList(mode);
    } else {
      setMode(defaultMode());
    }
  }, [mode, forceMode]);

  // Using a separate handler ensures that we do not store the default mode.
  // This will allow `prefers-color-scheme` to function as expected when
  // returning at a later date when the user's preference has changed.
  function handleUserSetMode(mode: Modes) {
    if (forceMode) return;

    setMode(mode);
    localStorage.setItem("theme", mode);
  }

  return (
    <ThemeContext.Provider
      value={{
        mode,
        setMode: handleUserSetMode,
        isForced: forceMode && Object.values(Modes).includes(forceMode),
      }}
    >
      <Head>
        <meta
          name="theme-color"
          content={mode === Modes.LIGHT ? "#fbfbfd" : "#000000"}
        />
      </Head>
      {children}
    </ThemeContext.Provider>
  );
};

export const useDarkMode = () => {
  return useContext(ThemeContext);
};
