import React, { useRef } from "react";
import cx from "classnames";
import type {
  StackedMeterProps,
  StackedMeterItemType,
} from "./StackedMeter.types";
import styles from "./StackedMeter.module.css";
import Tooltip from "../Tooltip";

/**
 * A StackedMeter is used for displaying relative amounts in a
 * progress bar style format.
 */
export default function StackedMeter({
  items,
  size = "md",
  className,
}: StackedMeterProps) {
  const totalValue = items.reduce((sum, item) => sum + item.value, 0);

  return (
    <div
      className={cx(styles.stackedMeter, className, {
        [styles[size]]: !!size,
      })}
    >
      {items.map((item) => (
        <StackedMeterItem
          key={item.name}
          item={item}
          percentage={totalValue === 0 ? 0 : item.value / totalValue}
        />
      ))}
    </div>
  );
}

const StackedMeterItem = ({
  item,
  percentage,
}: {
  item: StackedMeterItemType;
  percentage: number;
}) => {
  if (percentage === 0) return null;
  const targetRef = useRef<HTMLDivElement>(null);
  const displayValue = Math.round(percentage * 10000) / 100;

  return (
    <>
      <div
        ref={targetRef}
        key={item.name}
        className={styles.stackedMeterItem}
        style={{ backgroundColor: item.color, width: `${percentage * 100}%` }}
      />
      <Tooltip targetRef={targetRef} position="top" title={item.name}>
        {displayValue}%
      </Tooltip>
    </>
  );
};
