import React from "react";
import classnames from "classnames/bind";
import styles from "./Callout.module.css";
import { CalloutProps } from "./Callout.types";
const cx = classnames.bind(styles);

import HelpIcon from "../images/help.svg";
import ErrorIcon from "../images/close.svg";
import SuccessIcon from "../images/checkmark.svg";
import WarningIcon from "../images/warning.svg";

const TYPE_TO_ICON: Record<CalloutProps["type"], typeof React.Component> = {
  help: HelpIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const Callout = ({ type, children }: CalloutProps) => {
  const Icon = TYPE_TO_ICON[type];

  return (
    <div className={cx("Callout", `Callout--${type}`)}>
      <div>
        <Icon className={cx("Callout--icon")} />
      </div>
      <div className={cx("Callout--children")}>{children}</div>
    </div>
  );
};

export default Callout;
