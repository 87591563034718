import { createContext, useContext } from "react";
import { StepWizardContextType } from "./StepWizard.types";

export const StepWizardContext =
  createContext<StepWizardContextType>(undefined);

export const useStepWizard = () => {
  const context = useContext(StepWizardContext);
  if (context === undefined) {
    throw new Error("useStepWizard must be used within a StepWizard");
  }

  return context;
};
