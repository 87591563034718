import useControlledComponent from "../hooks/useControlledComponent";
import styles from "./TextInput.module.css";
import { styles as inputStyles } from "../Input";
import type { TextInputProps } from "./TextInput.types";
import classnames from "classnames/bind";
import React, { forwardRef } from "react";
import { useBorderGradient } from "../hooks/useBorderGradient";

const cx = classnames.bind({ ...styles, ...inputStyles });

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    value,
    defaultValue,
    onChange,
    className,
    size = "md",
    validation,
    icon,
    rightIcon,
    // Typical input[type=text] attributes such as title, disabled, pattern,
    // required
    ...rest
  } = props;

  const borderGradient = useBorderGradient();
  const { displayValue, onValueChange } = useControlledComponent({
    value,
    defaultValue,
    onChange,
  });

  return (
    <div
      className={cx(
        "TextInput",
        `TextInput--size-${size}`,
        {
          "TextInput--has-icon": !!icon,
          "TextInput--has-right-icon": !!rightIcon,
        },
        className
      )}
    >
      {icon && <div className={cx("TextInput__Icon")}>{icon}</div>}

      <input
        ref={ref}
        className={cx("Input", `Input--size-${size}`, {
          "Input--BorderGradient": borderGradient,
          "Input--validation": !!validation && !rightIcon,
          [`Input--validation-${validation}`]: !!validation && !rightIcon,
        })}
        value={displayValue}
        onChange={(e) => onValueChange(e.target.value)}
        {...rest}
      />

      {rightIcon && (
        <div className={cx("TextInput__Icon", "TextInput__Icon--right")}>
          {rightIcon}
        </div>
      )}
    </div>
  );
});

TextInput.displayName = "TextInput";

export default TextInput;
