import type { AvatarProps } from "../Avatar/Avatar.types";
import Tag from "../Tag";
import styles from "./AvatarGroup.module.css";
import type { AvatarGroupProps } from "./AvatarGroup.types";
import classnames from "classnames/bind";
import React, { cloneElement } from "react";

const cx = classnames.bind(styles);

// It becomes impractical to have numbers beyond 100. And we'll also have to
// deal with overflow issues. So borrow a page from iOS notifications, and cap
// this number at 99.
const MAX_DISPLAYED_NUM_HIDDEN = 99;

export default function AvatarGroup(props: AvatarGroupProps) {
  const {
    size = "md",
    className,
    children,
    max = Number.MAX_SAFE_INTEGER,
    hoverable = false,
    ...rest
  } = props;

  const cn = cx("AvatarGroup", `AvatarGroup--size-${size}`, className);
  const avatarCn = cx(
    "AvatarGroup__avatar",
    `AvatarGroup__avatar--size-${size}`,
    {
      "AvatarGroup__avatar--hoverable": hoverable,
    }
  );

  const avatars = React.Children.toArray(children)
    .slice(0, max)
    .map((child, index) => {
      if (!React.isValidElement<AvatarProps>(child)) return child;

      return cloneElement(child, {
        // It's probably not too helpful to pass `className` to the child with
        // the hope of adding the rounded corners and border as the child is not
        // guaranteed to be an Avatar component. The styles should probably be
        // applied directly to the Avatar component.
        className: avatarCn,
        // It's probably not too helpful to pass `size` to the child since the
        // child is not guaranteed to be an avatar. In the only usage I've found
        // so far, the child is actually a Link component wrapped around an
        // Avatar component.
        //
        // The application of `size` to the child is the reason the call to
        // React.isValidElement requires `AvatarProps` as a type.
        size,
        hoverable,
        // It is also probably not too helpful to write the key, as it is more
        // than likely the children have been created inside of a looping
        // function in another component. The key will be added there.
        key: index,
      });
      // After thinking it through, it is probably more useful to generalize
      // this component as a HorizontalStack component. 🤔
    });

  // 🤔 I'm not sure if counting children is a robust approach.
  const numChildren = React.Children.count(children);

  if (numChildren > max) {
    const numHidden = Math.min(numChildren - max, MAX_DISPLAYED_NUM_HIDDEN);

    avatars.push(
      <Tag
        key="AvatarGroupNumHidden"
        size={size}
        className={cx("AvatarGroup__numHidden")}
      >{`+${numHidden}`}</Tag>
    );
  }

  return (
    <div className={cn} {...rest}>
      {avatars}
    </div>
  );
}
