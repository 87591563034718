import React from "react";

import styles from "./DescriptionTable.module.css";

type DescriptionProps = React.PropsWithChildren<{
  term: string;
}>;

const Description: React.FunctionComponent<DescriptionProps> = (props) => (
  <tr className={styles.Description}>
    <th className={styles.DescriptionTerm}>{props.term}</th>
    <td className={styles.DescriptionDetails}>{props.children}</td>
  </tr>
);

export default Description;
