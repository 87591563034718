import Notification from "../../Notification";
import type { PolymorphicRef } from "../../types";
import styles from "./PillTab.module.css";
import type { PillTabProps } from "./PillTab.types";
import classnames from "classnames/bind";
import React, { forwardRef } from "react";

const cx = classnames.bind(styles);

const PillTab = forwardRef(
  <C extends React.ElementType = "button">(
    props: PillTabProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const {
      element,
      active,
      count,
      size = "md",
      danger,
      icon,
      className,
      children,
      ...rest
    } = props;
    const Element = element || "button";
    const hasCount = count != null; // not null or undefined

    const cn = cx(
      cx(
        "PillTab",
        `PillTab--size-${size}`,
        {
          "PillTab--state-inactive": !active,
          "PillTab--state-active": active,
          "PillTab--has-count": hasCount,
          "PillTab--has-icon": !!icon,
          "PillTab--color-unsafe": danger,
        },
        className
      )
    );

    return (
      <Element className={cn} ref={ref} {...rest}>
        <div className={cx("PillTab__children")}>{children}</div>
        {!!icon && <div className={cx("PillTab__Icon")}>{icon}</div>}
        {hasCount && (
          <span className={cx("PillTab__count")}>
            <Notification count={count} />
          </span>
        )}
      </Element>
    );
  }
);

PillTab.displayName = "PillTab";

export default PillTab;
