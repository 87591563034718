import eip55 from "eip55";
import { ethers } from "ethers";

import { Erc721__factory } from "typechain/factories/Erc721__factory";

import {
  ArchiveOfFeelings__factory,
  BackgroundRegistry__factory,
  BasicSingleRecordedRedeemer__factory,
  Defybirds__factory,
  Erc721Voucher__factory,
  Gnosis__factory,
  Grails__factory,
  Grails2__factory,
  Grails2MintPass__factory,
  Grails3__factory,
  Grails3MintPass__factory,
  MoonbirdFeaturesRegistry__factory,
  Moonbirds__factory,
  MoonbirdsInChainRenderer__factory,
  Oddities__factory,
  ProofBackgroundRegistry__factory,
  ProofCollective__factory,
  DelegateCash__factory,
  ProofOfConference__factory,
} from "../../typechain";
import { contracts, defaultChainId } from "./constants";

const CONTRACT_ADDRESS_TO_NAME = {
  [contracts.PROOF_COLLECTIVE]: "PROOF Collective",
  [contracts.MOONBIRDS]: "Moonbirds",
  [contracts.ODDITIES]: "Oddities",
};

export const contractAddressToName = (contractAddress: string) => {
  return CONTRACT_ADDRESS_TO_NAME[eip55.encode(contractAddress)];
};

export const provider = new ethers.providers.InfuraWebSocketProvider(
  defaultChainId,
  process.env.NEXT_PUBLIC_INFURA_ID
);

export const archiveOfFeelingsContract = ArchiveOfFeelings__factory.connect(
  contracts.ARCHIVE_OF_FEELINGS,
  provider
);

export const proofContract = ProofCollective__factory.connect(
  contracts.PROOF_COLLECTIVE,
  provider
);

export const proofBackgroundRegistryContract =
  ProofBackgroundRegistry__factory.connect(
    contracts.PROOF_BACKGROUND_REGISTRY,
    provider
  );

export const moonbirdsContract = Moonbirds__factory.connect(
  contracts.MOONBIRDS,
  provider
);

export const moonbirdsInChainFeaturesRegistryContract =
  MoonbirdFeaturesRegistry__factory.connect(
    contracts.MOONBIRDS_INCHAIN_FEATURES_REGISTRY,
    provider
  );

export const moonbirdsInChainBackgroundRegistryContract =
  BackgroundRegistry__factory.connect(
    contracts.MOONBIRDS_INCHAIN_BACKGROUND_REGISTRY,
    provider
  );

export const moonbirdsInChainRendererContract =
  MoonbirdsInChainRenderer__factory.connect(
    contracts.MOONBIRDS_INCHAIN_RENDERER,
    provider
  );

export const odditiesContract = Oddities__factory.connect(
  contracts.ODDITIES,
  provider
);

export const grailsContract = Grails__factory.connect(
  contracts.GRAILS,
  provider
);

export const grails2Contract = Grails2__factory.connect(
  contracts.GRAILS_2,
  provider
);

export const grails2MintPassContract = Grails2MintPass__factory.connect(
  contracts.GRAILS_2_MINT_PASS,
  provider
);

export const grails3Contract = Grails3__factory.connect(
  contracts.GRAILS_3,
  provider
);

export const grails3MintPassContract = Grails3MintPass__factory.connect(
  contracts.GRAILS_3_MINT_PASS,
  provider
);

export const defybirdsContract = Defybirds__factory.connect(
  contracts.DEFYBIRDS,
  provider
);

export const basicSingleRecordedRedeemerContract =
  BasicSingleRecordedRedeemer__factory.connect(
    contracts.BASIC_SINGLE_RECORDED_REDEEMER,
    provider
  );

// Use these to make all grails code event agnostic.
export const currentGrailsContract = grails3Contract;
export const currentGrailsMintPassContract = grails3MintPassContract;

export const proofOfConference = ProofOfConference__factory.connect(
  contracts.PROOF_OF_CONFERENCE,
  provider
);

// Non-PROOF contracts
export const delegateCashContract = DelegateCash__factory.connect(
  contracts.DELEGATE_CASH,
  provider
);

export const gnosisContract = (contractAddress: string) =>
  Gnosis__factory.connect(contractAddress, provider);

export const erc721Contract = (contractAddress: string) =>
  Erc721__factory.connect(contractAddress, provider);

export const erc721VoucherContract = (
  contractAddress: string,
  signer?: ethers.Signer
) => Erc721Voucher__factory.connect(contractAddress, signer || provider);
