import { useState } from "react";

export type ControlledComponentHookOptions<T> = {
  value?: T;
  defaultValue?: T;
  onChange?: (newValue: T) => void;
};

export type OptionallyControlledComponent<T> = {
  mode: "controlled" | "uncontrolled";
  displayValue: T;
  onValueChange: (newValue: T) => void;
};

/**
 * Handles the logic for a form component that can be either controlled
 * or uncontrolled. If value is anything but undefined, then the component
 * will operate in controlled mode, meaning that the value is controlled outside
 * of the component via props. If no onChange handler is given in controlled mode,
 * an error is output to the console since the component won't be able to update state.
 */
export default function useControlledComponent<T>({
  value,
  defaultValue,
  onChange,
}: ControlledComponentHookOptions<T>) {
  const [internalValue, setInternalValue] = useState(defaultValue);

  if (value === undefined && defaultValue === undefined) {
    throw new Error(
      "Either value or defaultValue must be set for this component"
    );
  }

  const mode = value !== undefined ? "controlled" : "uncontrolled";
  if (value !== undefined && onChange === undefined) {
    // eslint-disable-next-line no-console
    throw new Error(
      "Component is being used in controlled mode without an onChange handler"
    );
  }

  const displayValue = value !== undefined ? value : internalValue;

  const onValueChange = (newValue: T) => {
    if (newValue === undefined) {
      throw new Error("Cannot update component with undefined value");
    }

    if (value !== undefined && onChange) {
      onChange(newValue);
    } else {
      if (onChange) onChange(newValue);
      setInternalValue(newValue);
    }
  };

  return {
    mode,
    displayValue,
    onValueChange,
  };
}
