import React from "react";
import useToggle from "../hooks/useToggle";
import { ComponentToggleProps } from "./ComponentToggle.types";

const ComponentToggle: React.FunctionComponent<ComponentToggleProps> = ({
  component,
  children,
}) => {
  const Component = component;
  const { isToggled, toggleOn, toggleOff } = useToggle(false);

  return (
    <>
      {children(toggleOn)}
      {isToggled && <Component onClose={toggleOff} />}
    </>
  );
};

export default ComponentToggle;
