import Text from "../Text";
import useControlledComponent from "../hooks/useControlledComponent";
import styles from "./Filter.module.css";
import type { FilterProps } from "./Filter.types";
import Caret from "../images/caret.svg";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export default function Filter({
  label,
  value,
  defaultValue,
  onChange,
  className,
  disabled = false,
  children,
  ...props
}: FilterProps) {
  const { displayValue, onValueChange } = useControlledComponent({
    value,
    defaultValue,
    onChange,
  });

  // This goes through the <option> elements, which are passed in as children to this
  // component, and finds the one that matches the current value of the component in
  // order to get the display text.
  const displayLabel = (
    React.Children.toArray(children).find(
      (child) => (child as React.ReactElement)?.props?.value === displayValue
    ) as React.ReactElement
  )?.props?.children;

  return (
    <div
      className={cx(
        "Filter",
        {
          "Filter--state-disabled": disabled,
        },
        className
      )}
      {...props}
    >
      <div className={cx("Filter__content")}>
        {label && (
          <Text color="primary" size="md">
            {label}:
          </Text>
        )}

        <Text color="primary" size="md" weight="semibold">
          {displayLabel}
        </Text>

        <Caret className={cx("Filter__caret")} />
      </div>

      {/* Actual <select> element is hidden until triggered */}
      <select
        value={displayValue}
        disabled={disabled}
        className={cx("Filter__select", {
          "Filter__select--state-disabled": disabled,
        })}
        onChange={(e) => onValueChange(e.target.value)}
      >
        {children}
      </select>
    </div>
  );
}
