/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ProofBackgroundRegistry,
  ProofBackgroundRegistryInterface,
} from "../ProofBackgroundRegistry";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IERC721",
        name: "proof_",
        type: "address",
      },
      {
        internalType: "contract IMoonbirds",
        name: "moonbirds_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "NotAuthorised",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyMoonbirdOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ParameterLengthMismatch",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "useProofBackground",
        type: "bool",
      },
    ],
    name: "ProofBackgroundSettingChanged",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
      {
        internalType: "bool",
        name: "toggle",
        type: "bool",
      },
    ],
    name: "blockAuthorisation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getEntry",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "proofer",
            type: "address",
          },
          {
            internalType: "bool",
            name: "useProofBackground",
            type: "bool",
          },
        ],
        internalType: "struct ProofBackgroundRegistry.RegistryEntry",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "bool[]",
        name: "useProofBackgrounds",
        type: "bool[]",
      },
    ],
    name: "setMultipleProofBackground",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "bool[]",
        name: "useProofBackgrounds",
        type: "bool[]",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "setMultipleProofBackgroundWithSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "useProofBackground",
        type: "bool",
      },
    ],
    name: "setProofBackground",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "useProofBackground",
        type: "bool",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "setProofBackgroundWithSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "usesProofBackground",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ProofBackgroundRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): ProofBackgroundRegistryInterface {
    return new utils.Interface(_abi) as ProofBackgroundRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ProofBackgroundRegistry {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as ProofBackgroundRegistry;
  }
}
