import Text from "../Text";
import useControlledComponent from "../hooks/useControlledComponent";
import styles from "./Checkbox.module.css";
import type { CheckboxProps } from "./Checkbox.types";
import classnames from "classnames/bind";
import React, { ChangeEvent } from "react";
import Checkmark from "../images/checkmark.svg";

const cx = classnames.bind(styles);

export default function Checkbox({
  size = "lg",
  className,
  value,
  defaultValue,
  onChange,
  label,
  disabled,
  ...props
}: CheckboxProps) {
  const { displayValue: checked, onValueChange } =
    useControlledComponent<boolean>({
      value,
      defaultValue,
      onChange,
    });

  const cn = cx(
    "Checkbox",
    {
      [`Checkbox--size-${size}`]: !!size,
      "Checkbox--checked": checked,
      "Checkbox--disabled": disabled,
    },
    className
  );

  const inputElement = (
    <div className={cn}>
      <Checkmark className={cx("Checkmark", "invert-color")} />
      <input
        type="checkbox"
        className={cx("Checkbox__input")}
        checked={checked}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onValueChange(e.target.checked);
        }}
        {...props}
      />
    </div>
  );

  if (!label) {
    return inputElement;
  }

  return (
    <label
      className={cx("Checkbox__label", {
        "Checkbox__label--disabled": disabled,
      })}
    >
      {inputElement}
      <Text
        color={disabled ? "inactive" : "primary"}
        size={size === "sm" ? "xs" : "md"}
        weight="semibold"
        className={cx("Checkbox__text")}
        element="span"
      >
        {label}
      </Text>
    </label>
  );
}
