import { createPortal } from "react-dom";
import Button from "../Button";
import Heading from "../Heading";
import CloseIcon from "../images/close.svg";
import styles from "./Modal.module.css";
import type { ModalProps } from "./Modal.types";
import classnames from "classnames/bind";
import React, { useRef } from "react";
import { ButtonStyle } from "../Button/Button.types";
import { Color } from "../types";
import { useEphemeralPortal } from "../hooks/useEphemeralPortal";
import useKeyboardEventListener from "../hooks/useKeyboardEventListener";

const cx = classnames.bind(styles);

export default function Modal({
  title = "",
  allowClose = true,
  className,
  children,
  onRequestClose,
}: ModalProps) {
  const bgRef = useRef<HTMLDivElement>(null);
  const modalRoot = useEphemeralPortal();

  useKeyboardEventListener(
    allowClose && onRequestClose ? { Escape: onRequestClose } : {}
  );

  function handleClickOutside(event: React.MouseEvent) {
    if (allowClose && bgRef.current === event.target) {
      onRequestClose?.();
    }
  }

  const hasTitleString = !!title && typeof title === "string";
  const hasTitleJsx = !!title && typeof title !== "string";

  return createPortal(
    <div ref={bgRef} className={cx("Modal")} onClick={handleClickOutside}>
      <div className={cx("Modal__position", className)}>
        <div
          className={cx("Modal__dialog", {
            "Modal__dialog--closeable": allowClose,
          })}
        >
          {hasTitleJsx && title}
          {hasTitleString && (
            <h2 className={cx(Heading.md, "Modal__heading")}>{title}</h2>
          )}

          {allowClose && (
            <Button
              icon={<CloseIcon />}
              className={cx("Modal__close")}
              color={Color.neutral}
              style={ButtonStyle.tertiary}
              size="md"
              onClick={onRequestClose}
            />
          )}

          <div className={cx("Modal__content")}>{children}</div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}
