import React from "react";
import Image from "next/future/image";

import Avatar from "../Avatar";
import MediaObject from "../MediaObject";
import OptionalLink, { type OptionalLinkProps } from "../OptionalLink";
import TwoLiner from "../TwoLiner";

import styles from "./BusinessCard.module.css";

/**
 * BusinessCard
 */
export type BusinessCardProps = {
  name: React.ReactNode;
  title: React.ReactNode;
  src: string;
  reverse?: boolean;
} & OptionalLinkProps;

const BusinessCard: React.FunctionComponent<BusinessCardProps> = (props) => (
  <OptionalLink
    href={props.href}
    target={props.target}
    rel={props.rel}
    className={styles.BusinessCard__link}
  >
    <MediaObject
      className={styles.BusinessCard}
      media={
        <Avatar className={styles.BusinessCard__avatar}>
          <Image
            src={props.src}
            alt={`${props.name} avatar`}
            width={44}
            height={44}
            className={styles.BusinessCard__image}
            quality={100}
          />
        </Avatar>
      }
      body={<TwoLiner primary={props.name} secondary={props.title} />}
      reverse={props.reverse}
    />
  </OptionalLink>
);

BusinessCard.defaultProps = {
  reverse: false,
};

export default BusinessCard;
