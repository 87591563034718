import styles from "./Text.module.css";

import type { TextProps } from "./Text.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export default function Text({
  element = "p",
  size = "md",
  // By not defining a default weight, we allow Text components to inherit weight from parents
  weight,
  color = "primary",
  invertColor,
  className,
  children,
}: TextProps) {
  const Element = element;

  const cn = cx(
    "Text",
    {
      [`Text--color-${color}`]: !!color,
      [`Text--size-${size}`]: !!size,
      [`Text--weight-${weight}`]: !!weight,
      "invert-color": invertColor
    },
    className
  );

  return <Element className={cn}>{children}</Element>;
}
