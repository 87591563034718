import * as Sentry from "@sentry/nextjs";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import NProgress from "nprogress";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";

import ErrorFallback from "src/components/ErrorFallback";
import { Gradients } from "src/components/Gradients";
import { AppConfigProvider } from "src/contexts/appConfig";
import { FeaturesProvider } from "src/contexts/features";
import { ThemeProvider, Modes } from "src/hooks/useDarkMode";
import { IdentityProvider } from "src/hooks/useIdentity";
import {
  queryClient,
  QueryClientProvider,
} from "src/providers/QueryClientProvider";
import Web3Provider from "src/providers/Web3Provider";

import "@proofxyz/moonlight/styles/fonts.css";
import "@proofxyz/moonlight/styles/colors.css";
import "@proofxyz/moonlight/styles/proof-dark.css";
import "@proofxyz/moonlight/styles/proof-light.css";
import "react-toastify/dist/ReactToastify.css";

import "src/styles/constants.css";
import "src/styles/globals.css";
import "src/styles/nprogress.css";

type CustomPageProps = {
  forceMode?: Modes;
};

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp(props: AppProps<CustomPageProps>) {
  const router = useRouter();
  const { Component, pageProps = {} } = props;
  const { forceMode, ...componentProps } = pageProps;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => Sentry.captureException(error)}
    >
      <AppConfigProvider>
        <FeaturesProvider>
          <QueryClientProvider client={queryClient}>
            <Web3Provider>
              <IdentityProvider>
                <ThemeProvider forceMode={forceMode}>
                  <ToastContainer limit={1} />
                  <Gradients />
                  <Component {...componentProps} key={router.asPath} />
                </ThemeProvider>
              </IdentityProvider>
            </Web3Provider>
          </QueryClientProvider>
        </FeaturesProvider>
      </AppConfigProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
