import { useRef, useEffect } from "react";

type Handler = ({ key }: KeyboardEvent) => void;
type HandlerMap = Record<string, Handler>;
type KeyboardEventName = "keydown" | "keyup";

function useKeyboardEventListener(
  handler: Handler | HandlerMap,
  eventName: KeyboardEventName = "keydown"
) {
  const savedHandler = useRef<Handler | HandlerMap>(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    function handleKeyboardEvent(event: KeyboardEvent) {
      const { key } = event;
      const { current } = savedHandler;
      let handler;

      if (typeof current === "function") {
        handler = current;
      } else if (Object.prototype.hasOwnProperty.call(current, key)) {
        handler = current[key];
      }

      handler?.(event);
    }

    window.addEventListener(eventName, handleKeyboardEvent);

    return () => {
      window.removeEventListener(eventName, handleKeyboardEvent);
    };
  }, [eventName]);
}

export default useKeyboardEventListener;
