import React, { useContext } from "react";

import { fetchAppConfig, useAppConfig } from "./appConfig";

export type FeatureKey = string;
export type FeatureValue = boolean;
export type FeaturesData = Record<FeatureKey, FeatureValue>;
export type Features = ReadonlyMap<FeatureKey, FeatureValue>;

/**
 * Fetch the feature configuration from Edge Config.
 */
export async function fetchFeaturesConfig() {
  const appConfig = await fetchAppConfig();
  return appConfig.features ?? {};
}

/**
 * Features factory
 * Creates a features API. Currently, it uses a simple Map to store the
 * features, but this could be replaced with a more sophisticated data type
 * as needed (e.g. to include experiment variants, etc.).
 */
export function createFeatures(value: FeaturesData = {}): Features {
  const map = new Map(Object.entries(value));

  return {
    forEach: map.forEach.bind(map),
    get: map.get.bind(map),
    has: map.has.bind(map),
    get size() {
      return map.size;
    },
    // The following methods are included to satisfy the Map interface.
    entries(): IterableIterator<[string, boolean]> {
      throw new Error("Function not implemented.");
    },
    keys(): IterableIterator<string> {
      throw new Error("Function not implemented.");
    },
    values(): IterableIterator<boolean> {
      throw new Error("Function not implemented.");
    },
    [Symbol.iterator](): IterableIterator<[string, boolean]> {
      throw new Error("Function not implemented.");
    },
  };
}

/**
 * Features context
 */
const featuresContext = React.createContext<Features>(createFeatures());

/**
 * Features context provider component
 */
export const FeaturesProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const appConfig = useAppConfig();
  const features = createFeatures(appConfig.features);
  return (
    <featuresContext.Provider value={features}>
      {children}
    </featuresContext.Provider>
  );
};

/**
 * Features context consumer hook
 */
export function useFeatures() {
  return useContext(featuresContext);
}

export default featuresContext;
