import type {
  GetPaginationItemsArgs,
  PaginationItem,
} from "./Pagination.types";

function range(start: number, end: number): number[] {
  const sign = start > end ? -1 : 1;
  return Array.from(
    { length: Math.abs(end - start) + 1 },
    (_, i) => start + i * sign
  );
}

function createPaginationItem(
  value: number,
  text: string = value.toString()
): PaginationItem {
  return {
    value,
    text,
  };
}

function createPaginationEllipsis(value: number): PaginationItem {
  return createPaginationItem(value, "…");
}

function createPaginationRange(start: number, end: number): PaginationItem[] {
  return range(start, end).map((value) => createPaginationItem(value));
}

export function getPaginationItems({
  count,
  value,
  siblingCount = 1,
}: GetPaginationItemsArgs): PaginationItem[] {
  // If there is a sibling count of 1 means there will always be at least 1 indicator before and after the selected page
  // In addition to the 2 siblings (one on each side), we will always show 2 items at the start, 2 items at the end, and the current value
  // e.g.: [1, '...', sibling, value, sibling, '...', count]
  const maxItems = 5 + 2 * siblingCount;

  // If the total number of indicators is fewer than the maxItems, just show them all (no ellipses)
  if (count <= maxItems) {
    return createPaginationRange(1, count);
  }

  // More than maxItems, currentTowards the start
  if (value <= 4) {
    return [
      ...createPaginationRange(1, 5),
      createPaginationEllipsis(5 + (siblingCount + 1)),
      createPaginationItem(count),
    ];
  }

  // Towards the end
  if (count - value <= 3) {
    return [
      createPaginationItem(1),
      createPaginationEllipsis(count - 4 - (siblingCount + 1)),
      ...createPaginationRange(count - 4, count),
    ];
  }

  // Towards the middle
  return [
    createPaginationItem(1),
    createPaginationEllipsis(value - siblingCount - (siblingCount + 1)),
    ...createPaginationRange(value - siblingCount, value + siblingCount),
    createPaginationEllipsis(value + siblingCount + (siblingCount + 1)),
    createPaginationItem(count),
  ];
}
