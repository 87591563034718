import Text from "../Text";
import styles from "./Badge.module.css";
import type { BadgeProps } from "./Badge.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles)

export default function Badge({
  text,
  imgSrc,
  imgHeight,
  imgWidth,
  className,
}: BadgeProps) {
  const cn = cx("Badge", className);

  return (
    <div className={cn}>
      <img
        src={imgSrc}
        style={{ width: `${imgWidth}px`, height: `${imgHeight}px` }}
      />
      <Text size="sm" color="primary" weight="semibold" className={cx("Badge__text")}>
        {text}
      </Text>
    </div>
  );
}
