import classnames from "classnames/bind";
import React from "react";

import styles from "./TabGroup.module.css";
import type { TabGroupProps } from "./TabGroup.types";

const cx = classnames.bind(styles);

/**
 * This is a super naive implementation, not sure if we want to build more
 * functionality into it at this point, e.g. treating it like a form input that
 * takes a value that sets the active tab and an onChange handler.
 */
function TabGroup({ children, className, ...props }: TabGroupProps) {
  const cn = cx("TabGroup", className);
  return (
    <div className={cn} {...props}>
      {children}
    </div>
  );
}

export default TabGroup;
