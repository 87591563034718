import React from "react";

import styles from "./ArticleMetaList.module.css";

/**
 * ArticleMetaListItem
 */
type ArticleMetaListItemProps = React.PropsWithChildren;

const ArticleMetaListItem: React.FunctionComponent<ArticleMetaListItemProps> = (
  props
) => <li className={styles.ArticleMetaListItem}>{props.children}</li>;

export default ArticleMetaListItem;
