import { getAll } from "@vercel/edge-config";
import React, { useContext, useEffect, useState } from "react";

import type { FeaturesData } from "./features";

export type AppConfig = {
  features?: FeaturesData;
  [key: string]: unknown;
};

/**
 * Fetch the app configuration from Edge Config.
 */
export async function fetchAppConfig(): Promise<AppConfig> {
  const appConfig = await getAll<AppConfig>();
  return appConfig ?? {};
}

/**
 * API endpoint for the feature configuration.
 */
const APP_CONFIG_API = "/api/config";

/**
 * Query the API for app configuration.
 */
export function useAppConfigData(): AppConfig {
  const [appConfigData, setAppConfigData] = useState({});
  useEffect(() => {
    (async () => {
      const res = await fetch(APP_CONFIG_API);
      const appConfigData = await res.json();
      setAppConfigData(appConfigData);
    })();
  }, []);
  return appConfigData;
}

/**
 * App config context
 */
const appConfigContext = React.createContext<AppConfig>({});

/**
 * App config context provider component
 */
export const AppConfigProvider: React.FC<React.PropsWithChildren> = (props) => (
  <appConfigContext.Provider value={useAppConfigData()}>
    {props.children}
  </appConfigContext.Provider>
);

/**
 * App config context consumer hook
 */
export function useAppConfig() {
  return useContext(appConfigContext);
}

export default appConfigContext;
