import cx from "classnames";
import React from "react";

import type { PropsWithClassName } from "../types";

import styles from "./Carousel.module.css";

/**
 * Carousel
 */
type InactiveCarouselProps = PropsWithClassName<React.PropsWithChildren>;

const InactiveCarousel: React.FC<InactiveCarouselProps> = (props) => (
  <div
    className={cx(styles.Carousel, styles.InactiveCarousel, props.className)}
  >
    <div className="slider-container">
      <div className="slider-frame">
        <div className="slider-list">
          <div className="slide">{props.children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default InactiveCarousel;
