export const Gradients = () => (
  <>
    {/* Proof 375 */}
    <svg aria-hidden="true" focusable="false" className="w-0 h-0 absolute">
      <linearGradient id="gradient-proof-375">
        <stop offset="0%" stopColor="#e672f7" />
        <stop offset="100%" stopColor="#8e4fdb" />
      </linearGradient>
    </svg>
  </>
);
