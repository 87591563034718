import styles from "./Avatar.module.css";
import type { AvatarProps } from "./Avatar.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export const DEFAULT_AVATAR_URL = "/images/illustrations/profile.gif";

export default function Avatar(props: AvatarProps) {
  const {
    size = "md",
    src = null,
    className,
    hoverable,
    active,
    badge,
    children,
    ...rest
  } = props;

  const cn = cx(
    "Avatar",
    {
      [`Avatar--size-${size}`]: !!size,
      "Avatar--hoverable": hoverable,
      "Avatar--active": active,
    },
    className
  );

  let renderChildren: React.ReactNode = null;

  if (React.Children.count(children) > 0) {
    renderChildren = children;
  } else {
    renderChildren = (
      <img
        src={src || DEFAULT_AVATAR_URL}
        alt="User avatar"
        className={cx("Avatar__image")}
      />
    );
  }

  return (
    <div className={cn} {...rest}>
      <div className={cx("Avatar__Content")}>{renderChildren}</div>
      {badge && <div className={cx("Avatar__Badge")}>{badge}</div>}
    </div>
  );
}
