import styles from "./AspectRatioContainer.module.css";
import type { AspectRatioContainerProps } from "./AspectRatioContainer.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

/**
 * Creates a div container that conforms to a specific aspect ratio.
 * In order for anything to display, you MUST give it a width of some kind.
 */
export default function AspectRatioContainer({
  x,
  y,
  className,
  children,
}: AspectRatioContainerProps) {
  const aspectRatio = (y / x) * 100;
  const style = {
    paddingTop: `${aspectRatio}%`,
  };

  return (
    <div className={cx("AspectRatioContainer", className)} style={style}>
      <div className={cx("AspectRatioContainer__content")}>{children}</div>
    </div>
  );
}
