import Heading from "../Heading";
import Text from "../Text";
import useForceRerender from "../hooks/useForceRerender";
import useWindowSize from "../hooks/useWindowSize";
import TooltipPointer from "../images/tooltip-pointer.svg";
import styles from "./Tooltip.module.css";
import type { TooltipProps } from "./Tooltip.types";
import classnames from "classnames/bind";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import useWindowScroll from "../hooks/useWindowScroll";

const cx = classnames.bind(styles);

let visibleRef: React.MutableRefObject<HTMLDivElement | null> | null = null;
let hideVisible: (() => void) | null = null;

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  targetRef,
  title,
  position = "top",
  offset = 0,
  delay = 150,
  alwaysVisible = false,
  className,
  children,
}) => {
  const [visible, setVisible] = useState(alwaysVisible);
  const selfRef = useRef<HTMLDivElement | null>(null);
  const pointerRef = useRef<HTMLDivElement | null>(null);
  const mountRef = useRef<HTMLDivElement | null>(null);

  // These two hooks are used to ensure proper positioning of the tooltip when
  // set to alwaysVisible.
  useForceRerender();
  useWindowSize();
  useWindowScroll({ enabled: visible });

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = document.createElement("div");
      document.body.appendChild(mountRef.current);
    }

    return () => {
      if (mountRef.current) {
        mountRef.current.remove();
        mountRef.current = null;
      }
    };
  }, [mountRef]);

  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (alwaysVisible || !targetRef.current || !selfRef.current) return;

    function handleMouseOver() {
      // Clear existing timeout to prevent the tooltip from closing.
      clearTimeout(timeoutId.current);

      // If another tooltip is visible, hide it before showing this one.
      if (visibleRef && visibleRef !== selfRef) {
        hideVisible?.();
      }

      // Show tooltip.
      setVisible(true);

      // Store reference to this tooltip so that it can be hidden if another
      // tooltip is shown.
      visibleRef = selfRef;
      hideVisible = () => setVisible(false);
    }

    function handleMouseLeave() {
      // Store the current timeout ID so that we can cancel it when the cursor
      // moves between the target and the tooltip, preventing flicker.
      timeoutId.current = setTimeout(() => setVisible(false), delay);
    }

    const target = targetRef.current;
    target.classList.add("Tooltip__target");
    target.addEventListener("mouseover", handleMouseOver);
    target.addEventListener("mouseleave", handleMouseLeave);

    const tooltip = selfRef.current;
    tooltip.addEventListener("mouseover", handleMouseOver);
    tooltip.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      target.classList.remove("Tooltip__target");
      target.removeEventListener("mouseover", handleMouseOver);
      target.removeEventListener("mouseleave", handleMouseLeave);
      tooltip.removeEventListener("mouseover", handleMouseOver);
      tooltip.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [alwaysVisible, targetRef.current, selfRef.current]);

  // Position tooltip relative to target.
  let tooltipStyle: CSSProperties | undefined = undefined;

  let pointerOffset = 0;

  if (targetRef.current && selfRef.current) {
    const targetRect = targetRef.current.getBoundingClientRect();
    const selfRect = selfRef.current.getBoundingClientRect();

    let top =
      targetRect.top + (document.documentElement.scrollTop ?? window.scrollY);
    let left = targetRect.left;

    const width = Math.min(selfRect.width, window.innerWidth);

    if (position === "top") {
      top -= selfRect.height + 8 + offset;
      left += (targetRect.width - width) / 2;
    } else if (position === "bottom") {
      top += targetRect.height + 8 + offset;
      left += (targetRect.width - width) / 2;
    } else if (position === "left") {
      top += (targetRect.height - selfRect.height) / 2;
      left -= width + 8 + offset;
    } else {
      top += (targetRect.height - selfRect.height) / 2;
      left += targetRect.width + 8 + offset;
    }

    // Keep track of the original position of the tooltip so that we can
    // calculate the offset of the pointer.
    const unadjustedLeft = left;

    // If the tooltip is off the screen, adjust its position.
    left = Math.max(left, 0);
    const right = left + width;
    if (right > window.innerWidth) {
      left -= right - window.innerWidth;
    }
    left += window.scrollX;

    tooltipStyle = {
      top: `${top}px`,
      left: `${left}px`,
      width: width,
    };

    // Move the pointer to the center of the target.
    pointerOffset = unadjustedLeft - left + window.scrollX;
    const gate = width / 2 - 18; // hardcoded width of pointer (16) + offset (2)
    pointerOffset = Math.max(Math.min(pointerOffset, gate), -gate);
  }

  if (!mountRef.current) return null;

  const showTooltip = (alwaysVisible || visible) && !!tooltipStyle;

  const tooltipCn = cx(
    "Tooltip",
    {
      "Tooltip--state-visible": showTooltip,
    },
    className
  );

  const pointerCn = cx(
    "Tooltip__pointer",
    `Tooltip__pointer--position-${position}`
  );

  return createPortal(
    <div ref={selfRef} className={tooltipCn} style={tooltipStyle}>
      {title && (
        <h2 className={cx(Heading.xs, "Tooltip__heading", "invert-color")}>
          {title}
        </h2>
      )}

      <Text size="sm" invertColor className={cx("Tooltip__text")}>
        {children}
      </Text>

      <TooltipPointer
        className={pointerCn}
        style={{ marginLeft: `${pointerOffset}px` }}
      />
    </div>,
    mountRef.current
  );
};

export default Tooltip;
