import { useEffect, useRef } from "react";

export const useEphemeralPortal = () => {
  const portalRoot = useRef(document.createElement("div"));

  useEffect(() => {
    const localRoot = portalRoot.current;
    document.body.appendChild(localRoot);

    return () => {
      document.body.removeChild(localRoot);
    };
  }, [portalRoot]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return portalRoot.current;
};
