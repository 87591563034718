export function toDecimal(num: number, precision = 0) {
  return parseFloat(num.toFixed(precision));
}

export function absMod(dividend: number, divisor: number) {
  return ((dividend % divisor) + divisor) % divisor;
}

export function clamp(value: number, a: number, b = 0) {
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  return Math.max(min, Math.min(value, max));
}
