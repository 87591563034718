import { utils } from "ethers";

import { gnosisContract } from "src/web3/contracts";

const GNOSIS_VALID_SIGNATURE_MAGIC_VALUE = "0x1626ba7e";

export default class GnosisSafe {
  constructor(address, message) {
    this.contract = gnosisContract(address);
    this.messageHash = utils.hashMessage(message);
  }

  waitForSignature() {
    return new Promise((resolve) => {
      this.contract.on("SignMsg", async (msgHash) => {
        // eslint-disable-next-line no-console
        console.log("Message signature detected");
        const magicValue = await this.contract.isValidSignature(
          this.messageHash,
          "0x"
        );

        // eslint-disable-next-line no-console
        console.log("Calculated magic value:", magicValue);
        const messageWasSigned =
          magicValue === GNOSIS_VALID_SIGNATURE_MAGIC_VALUE;

        if (messageWasSigned) {
          this.contract.removeAllListeners();
          resolve(msgHash);
        }
      });
    });
  }
}
