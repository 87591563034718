import { useState, useEffect } from "react";

function useForceRerender() {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (!rendered) {
      setImmediate(() => {
        setRendered(true);
      });
    }
  }, []);
}

export default useForceRerender;
