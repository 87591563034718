import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

/**
 * YouTube
 */
export type YouTubeProps = {
  id: string;
  title: string;
  width?: number;
  height?: number;
  // The poster prop does not refer to the same type of value as used by the
  // LiteYouTubeEmbed component. This prop represents the URL of the poster
  // image. The LiteYouTubeEmbed component uses one of the following values:
  // - "hqdefault" (default)
  // - "mqdefault"
  // - "sddefault"
  // - "maxresdefault"
  // @see https://www.npmjs.com/package/react-lite-youtube-embed
  // The "maxresdefault" poster image appears to be available on most recent
  // videos, but it is not always available on older videos. It is for this
  // reason that Paul Irish prefers the "hqdefault" in his original
  // lite-youtube-embed. However, this format is visibly inferior to the
  // "maxresdefault" format.
  // By default, we prefer "maxresdefault". If the default poster image appears,
  // we should include
  poster?: string;
  list?: string;
};

const YouTube: React.FunctionComponent<YouTubeProps> = (props) => {
  // https://developers.google.com/youtube/player_parameters
  const params = new URLSearchParams();

  // Remove YouTube branding from player controls.
  params.set("modestbranding", "1");

  // Including a list will allow us to curate related videos displayed when the
  // player is paused.
  if (props.list) {
    params.set("list", props.list);
  }

  return (
    <LiteYouTubeEmbed
      id={props.id}
      title={props.title}
      aspectWidth={props.width ?? 16}
      aspectHeight={props.height ?? 9}
      params={params.toString()}
      poster={"maxresdefault"}
      thumbnail={props.poster}
    />
  );
};
export default YouTube;
