import type { Argument } from "classnames";
import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  PropsWithChildren,
} from "react";

/**
 * This is a generic, reusable implemnetation of a polymorphic component.
 * @see https://www.freecodecamp.org/news/build-strongly-typed-polymorphic-components-with-react-and-typescript/#:~:text=In%20the%20world%20of%20React,already%20used%20a%20Polymorphic%20component
 */
export type PolymorphicRef<C extends ElementType> =
  ComponentPropsWithRef<C>["ref"];

type AsProp<C extends ElementType> = {
  element?: C;
};

type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P);

export type PolymorphicComponentProp<
  C extends ElementType,
  Props = Record<string, unknown>
> = PropsWithChildren<Props & AsProp<C>> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

export type PolymorphicComponentPropWithRef<
  C extends ElementType,
  Props = Record<string, unknown>
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> };

export enum Color {
  purple = "purple",
  neutral = "neutral",
  danger = "danger",
}

export type PropsWithClassName<P = unknown> = P & { className?: Argument };
