import DisplayText from "../DisplayText";
import Text from "../Text";
import cx from "classnames";
import React, { PropsWithChildren } from "react";

import styles from "./ArticleHeader.module.css";
import Kicker from "../Kicker";

/**
 * ArticleHeader
 */
type ArticleHeaderProps = PropsWithChildren<{
  kicker: React.ReactNode;
  headline: React.ReactNode;
  weight?: "bold";
  subHeadline?: React.ReactNode;
}>;

const ArticleHeader: React.FunctionComponent<ArticleHeaderProps> = (props) => (
  <header>
    <Kicker className={styles.ArticleHeader__kicker}>{props.kicker}</Kicker>
    <h1
      className={cx(DisplayText.lg, {
        [DisplayText.bold]: props.weight === "bold",
      })}
    >
      {props.headline}
    </h1>
    {!props.subHeadline ? null : <Text size="xl">{props.subHeadline}</Text>}

    {props.children && (
      <div className={styles.ArticleHeader__children}>{props.children}</div>
    )}
  </header>
);

export default ArticleHeader;
