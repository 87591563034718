import type { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Color } from "../types";

/**
 * The style of the button, which is directly tied to its level
 * of visual importance.
 */
export enum ButtonStyle {
  primary = "buttonPrimary",
  secondary = "buttonSecondary",
  tertiary = "buttonTertiary",
  action = "buttonAction",
}

/**
 * The size of the button, which affects its padding, font size, etc.
 */
export type ButtonSize = "sm" | "md" | "lg";

export type ButtonProps = PropsWithChildren<{
  color?: Color;
  style?: ButtonStyle;
  size?: ButtonSize;
  fullWidth?: boolean;
  icon?: JSX.Element;
  showCaret?: boolean;
}> &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "style">;
