import classnames from "classnames/bind";
import React from "react";
import useControlledComponent from "../hooks/useControlledComponent";

import { styles as inputStyles } from "../Input";
import Text from "../Text";
import styles from "./Select.module.css";
import type { SelectProps } from "./Select.types";
import Caret from "../images/caret.svg";

const cx = classnames.bind({ ...styles, ...inputStyles });

const Select: React.FunctionComponent<SelectProps> = ({
  placeholder,
  disabled,
  value,
  defaultValue,
  onChange,
  children,
  ...selectProps
}) => {
  const { displayValue, onValueChange } = useControlledComponent({
    value,
    defaultValue,
    onChange,
  });

  let valueOrPlaceholder: string;
  if (displayValue) {
    valueOrPlaceholder = (
      React.Children.toArray(children).find(
        (child) =>
          (child as React.ReactElement)?.props?.value?.toString() ===
          displayValue
      ) as React.ReactElement
    )?.props?.children;
  } else {
    valueOrPlaceholder =
      placeholder && placeholder.length > 0 ? placeholder : "Select one...";
  }

  return (
    <div
      className={cx("Select", "Input__PointerTarget", {
        "Input--has-value": !!displayValue,
        "Input--disabled": disabled,
      })}
    >
      <select
        className={cx("Input__HiddenInput")}
        value={displayValue}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => onValueChange(e.target.value)}
        {...selectProps}
      >
        {children}
      </select>

      <div className={cx("Select__Input", "Input")}>
        <Text
          size="md"
          weight="300"
          color={displayValue ? "primary" : "secondary"}
        >
          {valueOrPlaceholder}
        </Text>

        <Caret className={cx("Select__Caret")} />
      </div>
    </div>
  );
};

export default Select;
