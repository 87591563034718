import * as Sentry from "@sentry/nextjs";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAccount } from "wagmi";

import { type ProofUser } from "src/hooks/identity/types";
import {
  viewerQuery,
  deleteAccountFetch,
  hasRefreshToken,
} from "src/queries/identity";

type IdentityContextValue = {
  viewer?: ProofUser | null;
  isAuthenticated?: boolean | null;
  isLoadingViewer: boolean;
  refetchUser: () => void;
  deleteAccount: () => void;
  setViewer: (user: ProofUser | null) => void;
};

const IdentityContext = createContext<IdentityContextValue>({
  viewer: null,
  isAuthenticated: null,
  isLoadingViewer: false,
  /* eslint-disable @typescript-eslint/no-empty-function */
  refetchUser: () => {},
  deleteAccount: () => {},
  setViewer: (_user) => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

type IdentityProviderProps = {
  children: React.ReactNode;
};

export const IdentityProvider = ({ children }: IdentityProviderProps) => {
  // State -----------------------------------------------------------------------------------------
  const [viewer, setViewer] = useState<ProofUser | null>(null);
  const { address } = useAccount();

  useEffect(() => {
    // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    if (viewer === null && !address) {
      Sentry.setUser(null);
    } else if (viewer !== null) {
      const {
        id,
        profile: { username },
      } = viewer;
      // A user object with undefined address is a logged in user
      // that doesn't have a wallet connected
      Sentry.setUser({ id, username, address });
    } else {
      // Wallet is connected, but user is logged out
      Sentry.setUser({ address });
    }
  }, [viewer, address]);

  // User fetch ------------------------------------------------------------------------------------
  // This only occurs is a refreshToken is present.
  const { isLoading: isLoadingViewer, refetch: refetchUser } = useQuery(
    "authenticatedUser",
    viewerQuery,
    {
      enabled: hasRefreshToken(),
      onSuccess: (userData) => {
        setViewer(userData);
      },
    }
  );

  // Available Methods -----------------------------------------------------------------------------
  const deleteAccount = async () => {
    if (viewer) {
      const { id } = viewer;

      // This will also clear refresh tokens on the server
      // TODO consider using identity API's /signout endpoint when it becomes available.
      await deleteAccountFetch(id);

      setViewer(null);
    }
  };

  // Check for the viewer object, not for the presence of an accessToken, since cookies are not
  // available on the server
  const isAuthenticated = !!viewer;

  // Values exposed via the hook -------------------------------------------------------------------
  const value = {
    isAuthenticated,
    isLoadingViewer,
    refetchUser,
    viewer,
    deleteAccount,
    setViewer,
  };

  return (
    <IdentityContext.Provider value={value}>
      {children}
    </IdentityContext.Provider>
  );
};

export const useIdentity = () => {
  return useContext(IdentityContext);
};
