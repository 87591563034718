import styles from "./Pagination.module.css";
import { getPaginationItems } from "./Pagination.helpers";

import type { PaginationProps } from "./Pagination.types";
import NextIcon from "../images/next.svg";
import PreviousIcon from "../images/previous.svg";
import classnames from "classnames/bind";
import React, { useMemo } from "react";
import { absMod } from "../helpers/number";

const cx = classnames.bind(styles);

// TODO implement siblingCount
export default function Pagination({
  count,
  page,
  unordered = false,
  wraparound = false,
  onChange,
  className,
}: PaginationProps) {
  // Do not render if there is only one page. (Also fixes a rendering issue when
  // count is 0.)
  if (count < 2) return null;

  const items = useMemo(
    () => getPaginationItems({ count, value: page }),
    [count, page]
  );

  const previousPage = page - 1;
  const nextPage = page + 1;
  const previousPageDisabled = !wraparound && previousPage < 1;
  const nextPageDisabled = !wraparound && nextPage > count;

  function handleChange(page: number) {
    if (wraparound) {
      // Convert from and back to 1-based indexing.
      const normalized = absMod(page - 1, count) + 1;
      onChange(normalized, normalized !== page);
    } else {
      onChange(page, false);
    }
  }

  return (
    <div
      className={cx(
        "Pagination",
        {
          "Pagination--unordered": unordered,
        },
        className
      )}
    >
      <button
        className={cx("Pagination__button", "Pagination__arrowButton")}
        disabled={previousPageDisabled}
        onClick={() => handleChange(previousPage)}
      >
        <PreviousIcon />
      </button>

      {items.map(({ value, text }) => (
        <button
          key={`pagination-${value}`}
          className={cx("Pagination__button", "Pagination__pageButton")}
          disabled={value === page}
          onClick={() => handleChange(value)}
        >
          <span className={cx("Pagination__pageNumber")}>{text}</span>
        </button>
      ))}

      <button
        className={cx("Pagination__button", "Pagination__arrowButton")}
        disabled={nextPageDisabled}
        onClick={() => handleChange(nextPage)}
      >
        <NextIcon />
      </button>
    </div>
  );
}
