import Checkmark from "../images/checkmark.svg";
import styles from "./SelectionButton.module.css";
import type { SelectionButtonProps } from "./SelectionButton.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export default function SelectionButton({
  selected,
  disabled = false,
  className,
  children,
  onClick,
  ...props
}: SelectionButtonProps) {
  if (!children) {
    children = (
      <>
        <div className={cx("SelectionButton__select")}>Select</div>
        <div className={cx("SelectionButton__selected")}>
          <Checkmark className={cx("SelectionButton__icon")} /> Selected
        </div>
        <div className={cx("SelectionButton__deselect")}>Deselect</div>
      </>
    );
  }

  const cn = cx(
    "SelectionButton",
    {
      "SelectionButton--state-selected": selected,
    },
    className
  );

  return (
    <button className={cn} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </button>
  );
}
