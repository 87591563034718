import type { AnchorHTMLAttributes, PropsWithChildren } from "react";
import { ButtonSize, ButtonStyle } from "../Button/Button.types";
import { Color } from "../types";

export enum AnchorStyle {
  default = "linkDefault",
}

type AnchorStyleProps = {
  style?: AnchorStyle;
  size?: never;
  icon?: never;
};

type ButtonStyleProps = {
  style: ButtonStyle;
  size?: ButtonSize;
  icon?: JSX.Element;
};

type StyleProps = AnchorStyleProps | ButtonStyleProps;

export type AnchorProps = PropsWithChildren<{
  color?: Color;
  fullWidth?: boolean;
  reverse?: boolean;
}> &
  StyleProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "style">;
