/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  MoonbirdsInChainRenderer,
  MoonbirdsInChainRendererInterface,
} from "../MoonbirdsInChainRenderer";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IMoonbirds",
        name: "moonbirds_",
        type: "address",
      },
      {
        internalType: "contract IFeaturesProvider",
        name: "userRegistry_",
        type: "address",
      },
      {
        internalType: "contract BackgroundRegistry",
        name: "backgroundRegistry_",
        type: "address",
      },
      {
        internalType: "contract Assembler",
        name: "assembler_",
        type: "address",
      },
      {
        internalType: "string",
        name: "offchainBaseURI_",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expected",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "actual",
        type: "uint256",
      },
    ],
    name: "InvalidDimensions",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "NotInchainYet",
    type: "error",
  },
  {
    inputs: [],
    name: "UnsupportedScalingFactor",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "artworkBMP",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "artworkPixels",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "scaleupFactor",
        type: "uint32",
      },
    ],
    name: "artworkURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features",
        name: "features",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint96",
            name: "backgroundId",
            type: "uint96",
          },
        ],
        internalType: "struct Mutators",
        name: "mutators",
        type: "tuple",
      },
      {
        internalType: "uint32",
        name: "scaleupFactor",
        type: "uint32",
      },
    ],
    name: "artworkURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint96",
            name: "backgroundId",
            type: "uint96",
          },
        ],
        internalType: "struct Mutators",
        name: "mutators",
        type: "tuple",
      },
      {
        internalType: "uint32",
        name: "scaleupFactor",
        type: "uint32",
      },
    ],
    name: "artworkURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "assembler",
    outputs: [
      {
        internalType: "contract Assembler",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "attributes",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "string",
            name: "value",
            type: "string",
          },
        ],
        internalType: "struct Attribute[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "backgroundRegistry",
    outputs: [
      {
        internalType: "contract BackgroundRegistry",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getFeatures",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getMutators",
    outputs: [
      {
        components: [
          {
            internalType: "uint96",
            name: "backgroundId",
            type: "uint96",
          },
        ],
        internalType: "struct Mutators",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "hasFeatures",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "hasMutators",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "proofRegistry",
    outputs: [
      {
        internalType: "contract IFeaturesProvider",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract Assembler",
        name: "assembler_",
        type: "address",
      },
    ],
    name: "setAssembler",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract BackgroundRegistry",
        name: "backgroundRegistry_",
        type: "address",
      },
    ],
    name: "setBackgroundRegistry",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "bmpScale_",
        type: "uint32",
      },
    ],
    name: "setBmpScale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "externalLinkBaseURL_",
        type: "string",
      },
    ],
    name: "setExternalLinkBaseURL",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "offchainBaseURI_",
        type: "string",
      },
    ],
    name: "setOffchainBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IFeaturesProvider",
        name: "proofRegistry_",
        type: "address",
      },
    ],
    name: "setProofRegistry",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IFeaturesProvider",
        name: "userRegistry_",
        type: "address",
      },
    ],
    name: "setUserRegistry",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "userRegistry",
    outputs: [
      {
        internalType: "contract IFeaturesProvider",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class MoonbirdsInChainRenderer__factory {
  static readonly abi = _abi;
  static createInterface(): MoonbirdsInChainRendererInterface {
    return new utils.Interface(_abi) as MoonbirdsInChainRendererInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MoonbirdsInChainRenderer {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as MoonbirdsInChainRenderer;
  }
}
