/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  MoonbirdFeaturesRegistry,
  MoonbirdFeaturesRegistryInterface,
} from "../MoonbirdFeaturesRegistry";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IERC721",
        name: "moonbirds_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "IncorrectProof",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "enum FeatureType",
        name: "",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "InvalidFeatures",
    type: "error",
  },
  {
    inputs: [],
    name: "MalformedProof",
    type: "error",
  },
  {
    inputs: [],
    name: "MoonbirdAlreadySet",
    type: "error",
  },
  {
    inputs: [],
    name: "MoonbirdNotSet",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAuthorised",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyMoonbirdOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ParameterLengthMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "RegistryClosed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "setter",
        type: "address",
      },
    ],
    name: "MoonbirdLandedInchain",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
      {
        internalType: "bool",
        name: "toggle",
        type: "bool",
      },
    ],
    name: "blockAuthorisation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getFeatures",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getSettooor",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "hasFeatures",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features",
        name: "features",
        type: "tuple",
      },
      {
        internalType: "bytes32[]",
        name: "proof",
        type: "bytes32[]",
      },
    ],
    name: "setFeatures",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features",
        name: "features",
        type: "tuple",
      },
      {
        internalType: "bytes32[]",
        name: "proof",
        type: "bytes32[]",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "setFeaturesWithSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features[]",
        name: "featuress",
        type: "tuple[]",
      },
      {
        internalType: "bytes32[][]",
        name: "proofs",
        type: "bytes32[][]",
      },
    ],
    name: "setMultipleFeatures",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "background",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "beak",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "body",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "eyewear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "headwear",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "outerwear",
            type: "uint8",
          },
        ],
        internalType: "struct Features[]",
        name: "featuress",
        type: "tuple[]",
      },
      {
        internalType: "bytes32[][]",
        name: "proofs",
        type: "bytes32[][]",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "setMultipleFeaturesWithSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "open",
        type: "bool",
      },
    ],
    name: "setOpen",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class MoonbirdFeaturesRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): MoonbirdFeaturesRegistryInterface {
    return new utils.Interface(_abi) as MoonbirdFeaturesRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MoonbirdFeaturesRegistry {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as MoonbirdFeaturesRegistry;
  }
}
