import React from "react";

import styles from "./ArticleByline.module.css";

/**
 * ArticleByline
 */
type ArticleBylineProps = React.PropsWithChildren;

const ArticleByline: React.FunctionComponent<ArticleBylineProps> = (props) => (
  <ul className={styles.ArticleByline}>{props.children}</ul>
);

export default ArticleByline;
