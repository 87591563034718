import {
  configureChains,
  createClient,
  createStorage,
  WagmiConfig,
} from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";

/**
 * Initialize client providers.
 */
const { provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [
    infuraProvider({
      apiKey: process.env.NEXT_PUBLIC_INFURA_ID,
    }),
  ]
);

/**
 * Initialize client connectors.
 */
const injectedConnector = new InjectedConnector({
  options: {
    shimDisconnect: true,
  },
});

type Connectors =
  | InjectedConnector
  | MetaMaskConnector
  | WalletConnectConnector
  | CoinbaseWalletConnector;

const connectors: Connectors[] = [injectedConnector];

// Add MetaMask connector, if not injected.
if (injectedConnector.name !== "MetaMask") {
  const metaMaskConnector = new MetaMaskConnector({
    options: {
      shimDisconnect: true,
    },
  });
  connectors.push(metaMaskConnector);
}

// Add WalletConnect connector, if not injected.
// https://wagmi.sh/docs/connectors/walletConnect
if (injectedConnector.name !== "WalletConnect") {
  const walletConnectConnector = new WalletConnectConnector({
    options: {
      qrcode: true,
      rpc: {
        // Changing the default RPC URL, since the default URL is heavily rate limited
        // https://discord.com/channels/492410046307631105/1022423280994496602/1033396883470692442
        1: `https://eth-mainnet.alchemyapi.io/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
      },
    },
  });
  connectors.push(walletConnectConnector);
}

// Add Coinbase Wallet connector, if not injected.
if (injectedConnector.name !== "Coinbase Wallet") {
  const coinbaseWalletConnector = new CoinbaseWalletConnector({
    options: {
      appName: "Moonbirds",
    },
  });
  connectors.push(coinbaseWalletConnector);
}

/**
 * Initialize client.
 * We disable the internal wagmi cache because it causes hydration issues. If something
 * is stored in the cache, wagmi will return its value even if the corresponding hook
 * has enabled set to false.
 */
const isClient = typeof window !== "undefined";
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
  storage: createStorage({
    storage: {
      getItem: (key) => {
        // Only disable the wagmi cache. This is what persists the results of network calls
        // across reloads. There are other cache keys that persist stuff like your connected
        // provider, which we want to keep. wagmi uses react-query internally, so we don't
        // have to worry about duplicate network calls as components re-render.
        if (!isClient || key === "wagmi.cache") return "";
        return window.localStorage.getItem(key);
      },
      setItem: (key, value) =>
        isClient ? window.localStorage.setItem(key, value) : null,
      removeItem: (key) =>
        isClient ? window.localStorage.removeItem(key) : null,
    },
  }),
});

/**
 * Create wagmi provider.
 * (This should be a component.)
 */
const Web3Provider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>;

/**
 * get Etherscan link helper
 */
const etherscanHost =
  process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID === "5"
    ? "goerli.etherscan.io"
    : "etherscan.io";

export const etherscanLink = (hash: string) =>
  `https://${etherscanHost}/tx/${hash}`;

export default Web3Provider;
