// The only environment variable we should really care about is NEXT_PUBLIC_DEFAULT_CHAIN_ID
// Everything else (contract addresses, URLs, etc) is determined based off the chain ID.

export enum Chain {
  Mainnet = 1,
  Goerli = 5,
}

export const defaultChainId: Chain = parseInt(
  process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID || String(Chain.Mainnet),
  10
);

export const isMainnet = defaultChainId === Chain.Mainnet;
export const isGoerli = defaultChainId === Chain.Goerli;

if (!isMainnet && !isGoerli) {
  throw new Error("Unknown ETH chain ID");
}

/**
 * Contract addresses
 */
type Contracts = {
  ARCHIVE_OF_FEELINGS: string;
  BASIC_SINGLE_RECORDED_REDEEMER: string;
  DEFYBIRDS: string;
  GRAILS_2_MINT_PASS: string;
  GRAILS_2: string;
  GRAILS_3_MINT_PASS: string;
  GRAILS_3: string;
  GRAILS: string;
  JOURNEY: string;
  MOONBIRDS_HOODIE_VOUCHER_BLACK: string;
  MOONBIRDS_HOODIE_VOUCHER_BLUE: string;
  MOONBIRDS_INCHAIN_BACKGROUND_REGISTRY: string;
  MOONBIRDS_INCHAIN_FEATURES_REGISTRY: string;
  MOONBIRDS_INCHAIN_RENDERER: string;
  MOONBIRDS: string;
  ODDITIES: string;
  PROOF_BACKGROUND_REGISTRY: string;
  PROOF_COLLECTIVE: string;
  PROOF_OF_STAFF: string;
  PROOF_OF_CONFERENCE: string;
  DELEGATE_CASH: string;
};

type ContractsConfigs = Record<Chain, Contracts>;

// NOTE - the below contract addresses must be EIP-55 encoded for consistency
const contractConfigs: ContractsConfigs = {
  [Chain.Mainnet]: {
    ARCHIVE_OF_FEELINGS: "0x24607c7602e52ce6b1ab4ae7b5196e9ae4c13762",
    BASIC_SINGLE_RECORDED_REDEEMER:
      "0xe4d3a12DA965504354F45db21D945270c1238BdB",
    DEFYBIRDS: "0x83D37295507F7C838f6a7dd1E41a4A81aC7C9a5E",

    GRAILS_2_MINT_PASS: "0x2C3FC1D826bC12027D05fBE3AeaCE0a2453bF9FD",
    GRAILS_2: "0xD78AFb925a21f87Fa0E35AbAE2aEad3F70Ced96B",
    GRAILS_3_MINT_PASS: "0x10F1d490B98b65CF5Ad8BABaB840a979a49C9b84",
    GRAILS_3: "0x503a3039e9ce236e9a12E4008AECBB1FD8B384A3",
    GRAILS: "0xb6329bd2741c4E5e91e26C4e653dB643E74B2b19",
    JOURNEY: "0xd5386794f57697ab4ecb930b049da70fc771900b",
    MOONBIRDS_HOODIE_VOUCHER_BLACK:
      "0x1e2478970840a23DB0AFd558159a400Bfb9a7B15",
    MOONBIRDS_HOODIE_VOUCHER_BLUE: "0xF265A8CAd057D4f9e8785BBb4E8780663E24bB5B",
    MOONBIRDS_INCHAIN_BACKGROUND_REGISTRY:
      "0xB7f6618F9E20fb56d5337000a90e540F4005c696",
    MOONBIRDS_INCHAIN_FEATURES_REGISTRY:
      "0xF8D83845DEb59EE43CF012e57731209A472baF8c",
    MOONBIRDS_INCHAIN_RENDERER: "0xb1bEfc9E7B76C1e846EBBf3e6E1Ab029C86e7435",
    MOONBIRDS: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
    ODDITIES: "0x1792a96E5668ad7C167ab804a100ce42395Ce54D",
    PROOF_BACKGROUND_REGISTRY: "0xB1499A80024Ff0c3AF27D0af125b0F838153D96c",
    PROOF_COLLECTIVE: "0x08D7C0242953446436F34b4C78Fe9da38c73668d",
    PROOF_OF_STAFF: "0xA540B82Af17A9b3443B730Cd0Ba40e177e12f1D2",
    PROOF_OF_CONFERENCE: "0x1a65Fa4E896a5fB0F3942E698DB13B87a9c15b52",
    // Non-PROOF contracts
    DELEGATE_CASH: "0x00000000000076A84feF008CDAbe6409d2FE638B",
  },
  [Chain.Goerli]: {
    ARCHIVE_OF_FEELINGS: "0x9fECCB9dbFa363E21D8B7F062e64067CF54b66E7",
    BASIC_SINGLE_RECORDED_REDEEMER:
      "0xeA5bc82867079486E52e70B556E7A595C6B81399",
    DEFYBIRDS: "0x746dE4aE6967c8559Af19583b7caFd8066D20D34",

    GRAILS_2_MINT_PASS: "",
    GRAILS_2: "0x4829daC97984cF0AD709839787B5259DF76AfCE2",
    GRAILS_3_MINT_PASS: "0xA99D796648B3c55878D6692f56eC9CE5eB5d4e74",
    GRAILS_3: "0xa9F96C020c175A9847fD9a9300bb07F221E38015",
    GRAILS: "0x1F095D81DE827F14b789a01fFB25262ac1d535A4",
    JOURNEY: "",
    MOONBIRDS_HOODIE_VOUCHER_BLACK:
      "0x0a5F857190044B25217fe28D6c1e2e747B607f3c",
    MOONBIRDS_HOODIE_VOUCHER_BLUE: "0xCee5347FC3EC40E9C3129a7b79CA7C28b68f3e89",
    MOONBIRDS_INCHAIN_BACKGROUND_REGISTRY:
      "0xFC405eB817f9B1ec48b15523892ea45d2Ed49c3C",
    MOONBIRDS_INCHAIN_FEATURES_REGISTRY:
      "0x2eB4AD92A23115404fD9A6FfB71955E80436c4A5",
    MOONBIRDS_INCHAIN_RENDERER: "0xEa786f034eD2467e3f4F5653D4e39DF954E6A959",
    MOONBIRDS: "0x3Cb5c6448eD756233fe9b91aBC4dB27a1cc17080",
    ODDITIES: "0xF355ED8Fbc10A22F775797394a52540Ce3B3eb6f",
    PROOF_BACKGROUND_REGISTRY: "0x3Ea485ED2A52415b05aA5cd4c90Ccc3B97E20F19",
    PROOF_COLLECTIVE: "0x1166ce7a0eb78fa0FcBD1AD42BEebA68447e07e6",
    PROOF_OF_STAFF: "0xc7e033A0FCECfF19a34fa9DD9d92879813197cE5",

    // Everyone's a winner
    // PROOF_OF_CONFERENCE: "0x05f2fB4cb8893736a0C95db67806C30e3B2FF3f8",
    // Normal rules
    PROOF_OF_CONFERENCE: "0x1b0014Da109A995648FC61d58A6721852D4D1aC7",

    // Non-PROOF contracts
    DELEGATE_CASH: "0x00000000000076A84feF008CDAbe6409d2FE638B",
  },
};

export const contracts = contractConfigs[defaultChainId];

/**
 * URLs
 */
const infuraId = process.env.NEXT_PUBLIC_INFURA_ID;

type Urls = {
  ETHERSCAN_URL: string;
  IMGIX_NFT_URL: string;
  IMGIX_NFT_ANIMATION_URL: string;
  NETWORK_URL: string;
  OPENSEA_URL: string;
};

type UrlsConfigs = Record<Chain, Urls>;

const urlConfigs: UrlsConfigs = {
  [Chain.Mainnet]: {
    ETHERSCAN_URL: "https://etherscan.io",
    IMGIX_NFT_URL: "https://proof-nft-image.imgix.net",
    IMGIX_NFT_ANIMATION_URL: "https://proof-nft-animation.imgix.net",
    NETWORK_URL: `https://mainnet.infura.io/v3/${infuraId}`,
    OPENSEA_URL: "https://opensea.io",
  },
  [Chain.Goerli]: {
    ETHERSCAN_URL: "https://goerli.etherscan.io",
    IMGIX_NFT_URL: "https://proof-nft-image-dev.imgix.net",
    IMGIX_NFT_ANIMATION_URL: "https://proof-nft-animation-dev.imgix.net",
    NETWORK_URL: `https://goerli.infura.io/v3/${infuraId}`,
    OPENSEA_URL: "https://testnets.opensea.io",
  },
};

const urls = urlConfigs[defaultChainId];

export const NETWORK_URL = urls.NETWORK_URL;
export const ETHERSCAN_URL = urls.ETHERSCAN_URL;
export const OPENSEA_URL = urls.OPENSEA_URL;
export const IMGIX_NFT_URL = urls.IMGIX_NFT_URL;
export const IMGIX_NFT_ANIMATION_URL = urls.IMGIX_NFT_ANIMATION_URL;
