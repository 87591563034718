import Bowser from "bowser";
import { useEffect, useState } from "react";

/**
 * I hate this but it lets us detect whether we're running in
 * Chrome because other browsers don't support the border gradients.
 * In those cases, we simply return a solid colored gradient.
 */
export const useBorderGradient = () => {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    setEnabled(
      browser.satisfies({
        chrome: ">1",
        chromium: ">1",
      }) || false
    );
  }, []);

  return enabled;
};
