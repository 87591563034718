import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

const DEFAULT_THROTTLE_WAIT = 50;

type UseWindowScrollOptions = {
  enabled?: boolean;
  wait?: number;
};

function useWindowScroll(options: UseWindowScrollOptions = {}) {
  const { enabled = true, wait = DEFAULT_THROTTLE_WAIT } = options;
  const [scrollX, setScrollX] = useState<number>();
  const [scrollY, setScrollY] = useState<number>();

  useEffect(() => {
    if (!enabled) return;

    // Throttle the scroll event to improve performance.
    const handleScroll = throttle(() => {
      setScrollX(window.scrollX);
      setScrollY(window.scrollY);
    }, wait);

    window.addEventListener("scroll", handleScroll);

    // Initialize scroll position.
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [enabled]);

  return [scrollX, scrollY];
}

export default useWindowScroll;
