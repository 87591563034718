import classnames from "classnames/bind";
import React from "react";

import styles from "./InputGroup.module.css";
import type { InputGroupProps } from "./InputGroup.types";
import Text from "../Text";
import Caps from "../Caps";

const cx = classnames.bind(styles);

const InputGroup: React.FunctionComponent<InputGroupProps> = ({
  label,
  helperText,
  fixedHeight,
  charLimit,
  inputRequired,
  children,
}) => {
  return (
    <div className={cx("InputGroup")}>
      <div className={cx("InputGroup__Top")}>
        <div className={cx("InputGroup__Label")}>
          {!!label && (
            <Text color="primary" size="md" weight="500">
              {label}
            </Text>
          )}
        </div>

        {inputRequired ? (
          <p className={cx(Caps.sm, "InputGroup__Required")}>Required</p>
        ) : null}
      </div>

      {children}

      {fixedHeight || helperText || charLimit ? (
        <div className={cx("InputGroup__HelperTextContainer")}>
          <div className={cx("InputGroup__HelperText")}>
            <Text size="xs" color="secondary">
              {helperText}
            </Text>
          </div>

          {charLimit && (
            <Text size="xs" color="secondary">
              {charLimit.length || 0} / {charLimit.maxLength}
            </Text>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InputGroup;
