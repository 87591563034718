import cx from "classnames";
import React from "react";
import Caps from "../Caps";

import styles from "./Kicker.module.css";
import type { KickerProps } from "./Kicker.types";

const Kicker: React.FunctionComponent<KickerProps> = (props) => {
  return (
    <small className={cx(Caps.md, styles.Kicker, props.className)}>
      {props.children}
    </small>
  );
};

export default Kicker;
