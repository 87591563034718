/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  SignatureGatedSeller,
  SignatureGatedSellerInterface,
} from "../SignatureGatedSeller";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "numMax",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeAfterTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeUntilTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
        ],
        internalType: "struct SignatureGated.Allowance",
        name: "",
        type: "tuple",
      },
    ],
    name: "InactiveAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "numMax",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeAfterTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeUntilTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
        ],
        internalType: "struct SignatureGated.Allowance",
        name: "",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "numLeft",
        type: "uint256",
      },
    ],
    name: "TooManyPurchasesRequested",
    type: "error",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "receiver",
                type: "address",
              },
              {
                internalType: "uint64",
                name: "numMax",
                type: "uint64",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "activeAfterTimestamp",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "activeUntilTimestamp",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nonce",
                type: "uint256",
              },
            ],
            internalType: "struct SignatureGated.Allowance",
            name: "allowance",
            type: "tuple",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct SignatureGated.SignedAllowance",
        name: "",
        type: "tuple",
      },
      {
        internalType: "address",
        name: "recovered",
        type: "address",
      },
    ],
    name: "UnauthorisedSigner",
    type: "error",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "numMax",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeAfterTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeUntilTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
        ],
        internalType: "struct SignatureGated.Allowance",
        name: "allowance",
        type: "tuple",
      },
    ],
    name: "digest",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "numMax",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeAfterTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activeUntilTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
        ],
        internalType: "struct SignatureGated.Allowance[]",
        name: "allowances",
        type: "tuple[]",
      },
    ],
    name: "numPurchasedWithAllowances",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                components: [
                  {
                    internalType: "address",
                    name: "receiver",
                    type: "address",
                  },
                  {
                    internalType: "uint64",
                    name: "numMax",
                    type: "uint64",
                  },
                  {
                    internalType: "uint256",
                    name: "price",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "activeAfterTimestamp",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "activeUntilTimestamp",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "nonce",
                    type: "uint256",
                  },
                ],
                internalType: "struct SignatureGated.Allowance",
                name: "allowance",
                type: "tuple",
              },
              {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
              },
            ],
            internalType: "struct SignatureGated.SignedAllowance",
            name: "signedAllowance",
            type: "tuple",
          },
          {
            internalType: "uint64",
            name: "num",
            type: "uint64",
          },
        ],
        internalType: "struct SignatureGated.SignedAllowancePurchase[]",
        name: "purchases",
        type: "tuple[]",
      },
    ],
    name: "purchase",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

export class SignatureGatedSeller__factory {
  static readonly abi = _abi;
  static createInterface(): SignatureGatedSellerInterface {
    return new utils.Interface(_abi) as SignatureGatedSellerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SignatureGatedSeller {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as SignatureGatedSeller;
  }
}
