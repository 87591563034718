import styles from "./MediaObject.module.css";
import type { MediaObjectProps } from "./MediaObject.types";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

const MediaObject: React.FC<MediaObjectProps> = (props) => {
  const orientation = props.orientation ?? "horizontal";
  const defaultAlign = orientation === "horizontal" ? "middle" : "left";
  const alignment = props.align ?? defaultAlign;
  return (
    <figure
      className={cx(
        "MediaObject",
        {
          "MediaObject--direction-reverse": props.reverse,
        },
        `MediaObject--orientation-${orientation}`,
        `MediaObject--align-${alignment}`,
        props.className
      )}
    >
      <div className={cx("MediaObject__media")}>{props.media}</div>
      <figcaption className={cx("MediaObject__body")}>{props.body}</figcaption>
      {!!props.action && (
        <div className={cx("MediaObject__action")}>{props.action}</div>
      )}
    </figure>
  );
};

export default MediaObject;
