import React, { Suspense } from "react";

import { getChildrenLength, getFirstChild } from "../helpers/dom";
import type { PropsWithClassName } from "../types";

import InactiveCarousel from "./InactiveCarousel";

// Lazy load Carousel functionality to reduce bundle size.
const ActiveCarousel = React.lazy(() => import("./ActiveCarousel"));

/**
 * Carousel
 */
type CarouselProps = PropsWithClassName<React.PropsWithChildren>;

const Carousel: React.FC<CarouselProps> = (props) => {
  const length = getChildrenLength(props.children);

  // Return null if there are no children.
  if (!length) return null;

  // The inactive carousel will be rendered while the ActiveCarousel component
  // is loading. It will also be rendered if the carousel has only one slide.
  const inactiveCarousel = (
    <InactiveCarousel {...props}>
      {getFirstChild(props.children)}
    </InactiveCarousel>
  );

  // Return full-featured Carousel if there are multiple children. Display the
  // first slide as an inactive carousel while lazy-loading the ActiveCarousel
  // component.
  if (length > 1) {
    return (
      <Suspense fallback={inactiveCarousel}>
        <ActiveCarousel {...props} />
      </Suspense>
    );
  }

  // Return inactive carousel there is only one child.
  return inactiveCarousel;
};

export default Carousel;
