import { Mutex } from "async-mutex";
import { Signer } from "ethers";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { QueryFunctionContext } from "react-query";

import { type ProofUser } from "src/hooks/identity/types";
import GnosisSafe from "src/lib/GnosisSafe";
import {
  ACCESS_TOKEN_NAME,
  IDENTITY_COOKIE_HOST,
  REFRESH_TOKEN_NAME,
} from "src/lib/cookies";

type SiweMutationOptions = {
  address: string;
  chainId: number;
  signer: Signer;
};

// Utility functions to DRY this code up in case we change the cookie library or
// how this works.
type TokenName = "accessToken" | "refreshToken";

export const getAccessToken = () => Cookies.get(ACCESS_TOKEN_NAME);
export const hasAccessToken = () => !!getAccessToken();
export const setAccessToken = (token: string) => {
  setToken("accessToken", token, {
    expires: DateTime.now().plus({ hour: 1 }).toJSDate(),
  });
};

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_NAME);
export const hasRefreshToken = () => !!getRefreshToken();
export const setRefreshToken = (token: string) => {
  setToken("refreshToken", token, {
    expires: DateTime.now().plus({ days: 30 }).toJSDate(),
  });
};

const setToken = (
  which: TokenName,
  value: string,
  options: Cookies.CookieAttributes = {}
) => {
  Cookies.set(`proof_${which}`, value, {
    path: "/",
    domain: IDENTITY_COOKIE_HOST,
    sameSite: "none",
    secure: true,
    ...options,
  });
};

const deleteToken = (which: TokenName) => {
  Cookies.remove(`proof_${which}`, {
    domain: IDENTITY_COOKIE_HOST,
    path: "/",
    sameSite: "none",
    secure: true,
  });
};

export const authenticatedFetch = async (
  path: string,
  options: RequestInit = {},
  authRetry = true
): Promise<Response> => {
  if (!hasAccessToken() && hasRefreshToken()) {
    // If the access token expires, but we still have a refresh token, we need to
    // fetch a new access token first.
    await refreshTokenQuery();
  }

  if (hasAccessToken()) {
    options.headers = {
      Authorization: `Bearer ${getAccessToken()}`,
      ...options.headers,
    };
  }

  const fetchResponse = await fetch(
    `${process.env.NEXT_PUBLIC_IDENTITY_API_URL}${path}`,
    {
      ...options,
      // set this to pass cookies
      // and so that the response's tokens (sent via Set-Cookie) are saved
      credentials: "include",
    }
  );

  // There is the risk of either a race condition or token expirations being out of sync.
  // If the response returns 401, we will attempt a refresh and then try again. We will
  // only attempt a retry once to avoid an infinite loop.
  if (fetchResponse.status === 401 && authRetry) {
    try {
      // If this fails, it will throw an exception and the retry won't occur
      const refreshResponse = await refreshTokenQuery();
      if (refreshResponse.ok) {
        return authenticatedFetch(path, options, false);
      }
    } catch (e) {
      // Instead of allowing the exception through, let's return the original response so that
      // this attempted retry is "invisible" to outside code.
    }
  }

  return fetchResponse;
};

export const fetchMe = async (): Promise<ProofUser> => {
  const resp = await authenticatedFetch("/v1/accounts/me");
  if (resp.ok) {
    return await resp.json();
  } else {
    if (resp.status === 401) {
      deleteToken("accessToken");
      deleteToken("refreshToken");
    }

    throw new Error(resp.statusText);
  }
};

const fetchAddress = async (address: string) => {
  const resp = await authenticatedFetch(`/v1/addresses/${address}`);
  if (resp.ok) {
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};

export const fetchUserByUsername = async (username: string) => {
  const resp = await authenticatedFetch(`/v1/profiles/${username}`);
  if (resp.ok) {
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};

export const fetchUsernameIsAvailable = async (username: string) => {
  const resp = await authenticatedFetch(`/v1/profiles/${username}/available`);
  if (resp.ok) {
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};

export const addressQuery = async ({
  queryKey,
}: QueryFunctionContext<string>): Promise<ProofUser> => {
  const [address] = queryKey;
  return await fetchAddress(address);
};

export const viewerQuery = async () => {
  return await fetchMe();
};

export const getNonceAndSignMessage = async ({
  address,
  chainId,
  signer,
}: SiweMutationOptions) => {
  const { SiweMessage } = await import("siwe");
  const nonceRes = await authenticatedFetch("/v1/nonce");

  const { nonce } = await nonceRes.json();

  const message = new SiweMessage({
    domain: window.location.host,
    address,
    statement:
      "Accept our terms of use and sign in to proof.xyz. This will not cost you any ETH.",
    uri: window.location.origin,
    version: "1",
    chainId,
    nonce,
  });

  const preparedMessage = message.prepareMessage();

  const signature = await signer.signMessage(preparedMessage);

  // Handle multisig
  if (signature === "0x") {
    const gnosis = new GnosisSafe(address, preparedMessage);
    await gnosis.waitForSignature();
  }

  return {
    message,
    signature,
  };
};

const tokenRefreshMutex = new Mutex();
export const refreshTokenQuery = async () => {
  /**
   * We ensure that only one execution of this code runs at a time in order to avoid race
   * conditions where two callers use authenticatedFetch() at the same time, which triggers
   * two token refreshes, one of which will fail. While this technically has no side effects
   * beyond a failed HTTP call, it's better and safer to be explicit about this.
   */
  return tokenRefreshMutex.runExclusive(async () => {
    // We check for the existence of the access token here in case multiple requests were fired
    // simultaneously and it's already been refreshed.
    if (hasAccessToken()) return { ok: true };

    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      // The only reason refreshToken would be missing would be after a failed refresh.
      // In that case, remove the accessToken so the user would be kicked out and ask
      // to log in again.
      deleteToken("accessToken");
      throw new Error("missing refresh token");
    }

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_IDENTITY_API_URL}/v1/sessions/refresh`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      }
    );

    if (res.ok) {
      const data = await res.json();
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
    } else {
      if (res.status < 500) {
        // We don't want to wipe everyone's session if we have a server issue. Only delete if there
        // is legitimately an issue with the request data.
        deleteToken("accessToken");
        deleteToken("refreshToken");
      }

      throw new Error("Unable to refresh access token");
    }

    return res;
  });
};

export const deleteRefreshToken = async (refreshToken: string) => {
  const resp = await authenticatedFetch(`/v1/sessions/${refreshToken}`, {
    method: "DELETE",
  });

  if (resp.ok) {
    deleteToken("accessToken");
    deleteToken("refreshToken");
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};

export const deleteAccountFetch = async (viewerId: string) => {
  const resp = await authenticatedFetch(`/v1/accounts/${viewerId}`, {
    method: "DELETE",
  });
  if (resp.ok) {
    deleteToken("accessToken");
    deleteToken("refreshToken");
    return await resp.json();
  } else {
    throw new Error(resp.statusText);
  }
};
