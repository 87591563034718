import { useState } from "react";

function useToggle(defaultState = false) {
  const [isToggled, setIsToggled] = useState(defaultState);

  return {
    isToggled,
    toggle(value = !isToggled) {
      setIsToggled(value);
    },
    toggleOn() {
      setIsToggled(true);
    },
    toggleOff() {
      setIsToggled(false);
    },
  };
}

export default useToggle;
