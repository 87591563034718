import classnames from "classnames/bind";
import React from "react";

import styles from "./TextArea.module.css";
import { styles as inputStyles } from "../Input";
import type { TextAreaProps } from "./TextArea.types";
import useControlledComponent from "../hooks/useControlledComponent";
import { useBorderGradient } from "../hooks/useBorderGradient";

const cx = classnames.bind({ ...styles, ...inputStyles });

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  value,
  defaultValue,
  onChange,
  className,
  size = "md",
  validation,
  ...props
}) => {
  const borderGradient = useBorderGradient();
  const { displayValue, onValueChange } = useControlledComponent({
    value,
    defaultValue,
    onChange,
  });

  return (
    <textarea
      className={cx(
        "Input",
        {
          "Input--BorderGradient": borderGradient,
          [`Input--size-${size}`]: !!size,
          "Input--validation": !!validation,
          [`Input--validation-${validation}`]: !!validation,
        },
        className
      )}
      value={displayValue}
      onChange={(e) => onValueChange(e.target.value)}
      {...props}
    />
  );
};

TextArea.displayName = "TextArea";

export default TextArea;
