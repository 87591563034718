import classnames from "classnames/bind";
import React from "react";

import styles from "./DescriptionTable.module.css";

const cx = classnames.bind(styles);

type DescriptionTableProps = React.PropsWithChildren<{
  size?: "sm" | "md";
}>;

const DescriptionTable: React.FunctionComponent<DescriptionTableProps> = (
  props
) => (
  <table
    className={cx("DescriptionTable", {
      "DescriptionTable--size-sm": props.size === "sm",
      "DescriptionTable--size-md": props.size === "md",
    })}
  >
    <tbody>{props.children}</tbody>
  </table>
);

DescriptionTable.defaultProps = {
  size: "md",
};

export default DescriptionTable;
