import cx from "classnames";
import React from "react";

import Link from "next/link";
import { PropsWithClassName } from "../types";
import styles from "./OptionalLink.module.css";

/**
 * OptionalLink
 */
export type OptionalLinkProps = React.PropsWithChildren<
  PropsWithClassName<{
    href?: string | null;
    target?: string;
    rel?: string;
  }>
>;

const OptionalLink: React.FC<OptionalLinkProps> = (props) => {
  if (!props.children) return null;
  if (!props.href) return <>{props.children}</>;
  return (
    <Link href={props.href}>
      <a
        className={cx(styles.OptionalLink, props.className)}
        target={props.target}
        rel={props.rel}
      >
        {props.children}
      </a>
    </Link>
  );
};

export default OptionalLink;
