import React, { useEffect, useState } from "react";
import { StepWizardContext } from "./StepWizard.context";
import { StepWizardNamedStep, StepWizardProps } from "./StepWizard.types";

const StepWizard = ({
  children,
  totalSteps = Infinity,
  initialStep = 0,
}: StepWizardProps) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [mappedSteps, setMappedSteps] = useState<Record<string, number>>({});

  const functionalChildren = typeof children === "function";

  useEffect(() => {
    if (functionalChildren) return;

    React.Children.forEach(children, (child, i) => {
      if (React.isValidElement(child)) {
        const childType = child.type;
        if (
          (typeof childType === "object" || typeof childType === "function") &&
          "stepName" in childType
        ) {
          const stepName = (child.type as StepWizardNamedStep).stepName;
          setMappedSteps((mappedSteps) => ({
            ...mappedSteps,
            [stepName]: i,
          }));
        }
      }
    });
  }, []);

  if (!functionalChildren && totalSteps === Infinity) {
    totalSteps = React.Children.count(children);
  }

  const stepName = Object.entries(mappedSteps).find(
    (mStep) => mStep[1] === currentStep
  )?.[0];

  const hasStep = (search: number | string) => {
    if (typeof search === "number") {
      return search < totalSteps;
    } else {
      return search in mappedSteps;
    }
  };

  const goToStep = (step: number | string) => {
    if (typeof step === "number") {
      if (step < 0 || step > totalSteps - 1) {
        throw new Error(
          `StepWizard: goToStep() step must be between 0 and ${
            totalSteps - 1
          }, got ${step}`
        );
      }

      setCurrentStep(step);
    } else {
      if (!(step in mappedSteps)) {
        throw new Error(
          `StepWizard: goToStep() step must be a valid step name, got ${step}`
        );
      }

      setCurrentStep(mappedSteps[step]);
    }
  };

  const value = {
    currentStep,
    stepName,
    nextStep: () => setCurrentStep(Math.min(currentStep + 1, totalSteps - 1)),
    prevStep: () => setCurrentStep(Math.max(currentStep - 1, 0)),
    goToStep,
    hasStep,
    totalSteps,
    hasMoreSteps: totalSteps === Infinity ? true : currentStep < totalSteps - 1,
  };

  return (
    <StepWizardContext.Provider value={value}>
      {functionalChildren
        ? children(currentStep)
        : React.Children.toArray(children)[currentStep] || null}
    </StepWizardContext.Provider>
  );
};

export default StepWizard;
