import { AnchorProps, AnchorStyle } from "./Anchor.types";
import classnames from "classnames/bind";

import React, { cloneElement, forwardRef } from "react";

import anchorStyles from "./Anchor.module.css";
import buttonStyles from "../Button/Button.module.css";
import { ButtonStyle } from "../Button";
import { Color } from "../types";

const anchorCx = classnames.bind(anchorStyles);
const buttonCx = classnames.bind(buttonStyles);

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>((props, ref) => {
  let { color } = props;
  const {
    size = "md",
    style = AnchorStyle.default,
    icon,
    className,
    children,
    fullWidth,
    href,
    reverse,
    ...rest
  } = props;

  // I don't love this. Need to figure out a cleaner way to switch stylesheets.
  // Maybe I should change this style enum stuff to explicitly passing in CSS
  // modules instead.
  const isAnchorStyle = Object.values<string>(AnchorStyle).includes(style);
  const isButtonStyle = Object.values<string>(ButtonStyle).includes(style);
  const cx = isAnchorStyle ? anchorCx : buttonCx;

  // Button default color is purple, whereas for links its neutral (works better
  // in prose). If a color is not set, we default based on the style.
  if (!color) {
    color = isAnchorStyle ? Color.neutral : Color.purple;
  }

  const cn = cx(
    {
      Anchor: isAnchorStyle,
      Button: isButtonStyle,
      [`Color--${color}`]: !!color,
      [`Size--${size}`]: !!size,
      [`Style--${style}`]: !!style,
      // Hate this but will come back to it
      "full-width": fullWidth,
      "Button--icon-only":
        (!!icon && children === undefined) ||
        React.Children.count(children) === 0,
      "Button--disabled": isButtonStyle && !href,
    },
    className
  );

  const iconElement = isButtonStyle && !!icon && (
    <span className={cx("Button__icon")}>{cloneElement(icon)}</span>
  );

  return (
    <a ref={ref} className={cn} href={href} {...rest}>
      {!reverse && iconElement}
      {children}
      {reverse && iconElement}
    </a>
  );
});

Anchor.displayName = "Anchor";
export default Anchor;
