import React from "react";

import styles from "./ArticleMetaList.module.css";

/**
 * ArticleMetaList
 */
type ArticleMetaListProps = React.PropsWithChildren;

const ArticleMetaList: React.FunctionComponent<ArticleMetaListProps> = (
  props
) => <ul className={styles.ArticleMetaList}>{props.children}</ul>;

export default ArticleMetaList;
