import { ButtonProps, ButtonStyle } from "./Button.types";
import classnames from "classnames/bind";
import React, { cloneElement, forwardRef } from "react";
import { Color } from "../types";
import Caret from "../images/caret.svg";

import styles from "./Button.module.css";
const cx = classnames.bind(styles);

/**
 * Button component
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  let { color = Color.purple } = props;
  const {
    style = ButtonStyle.primary,
    size = "md",
    fullWidth,
    className,
    icon,
    showCaret,
    children,
    ...rest
  } = props;

  if (style === ButtonStyle.action) {
    color = Color.neutral;
  }

  const cn = cx(
    "Button",
    {
      [`Color--${color}`]: !!color,
      [`Size--${size}`]: !!size,
      [`Style--${style}`]: !!style,
      "full-width": fullWidth,
      "Button--icon-only":
        (!!icon && children === undefined) ||
        React.Children.count(children) === 0,
    },
    className
  );

  return (
    <button ref={ref} className={cn} {...rest}>
      {!!icon && (
        <span className={cx("Button__icon")}>{cloneElement(icon)}</span>
      )}

      {children}

      {showCaret && <Caret className={cx("Button__caret")} />}
    </button>
  );
});

Button.displayName = "Button";

export default Button;
