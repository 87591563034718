import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useEphemeralPortal } from "../hooks/useEphemeralPortal";

import CloseIcon from "../images/close.svg";

import styles from "./Lightbox.module.css";
import { LightboxProps } from "./Lightbox.types";

export default function Lightbox({ children, onRequestClose }: LightboxProps) {
  const lightboxRoot = useEphemeralPortal();

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onRequestClose();
      }
    };

    document.body.addEventListener("keydown", handleEscape);

    return () => {
      document.body.removeEventListener("keydown", handleEscape);
    };
  });

  return createPortal(
    <div className={styles.lightbox} onClick={onRequestClose}>
      <button className={styles.closeButton} onClick={onRequestClose}>
        <CloseIcon />
      </button>

      <div className={styles.mediaContainer}>{children}</div>
    </div>,
    lightboxRoot
  );
}
